import React, { useEffect, useState, useMemo } from 'react'
import { StyleSheet, useColorScheme, SafeAreaView, ActivityIndicator, Text } from 'react-native'
import { observer } from 'mobx-react-lite'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { StackHeader } from 'components/Navigation'
import { Repair, Technique, Precautions, Note, Browser } from 'screens'
import { getTheme } from 'utils'
import { Theme } from 'styles'

const RepairStack = observer(() => {

	const scheme = useColorScheme()
  const theme = useMemo(() => getTheme(scheme, 'repair'), [scheme])
  const Stack = createNativeStackNavigator()

  const styles = StyleSheet.create({
    containerStyle: {
      paddingTop: Theme.spacing.layout + Theme.spacing.thin,
      paddingBottom: Theme.spacing.thin,
      backgroundColor: theme.colors.background,
    },
  })

  return (
    <NavigationContainer independent={true} theme={theme}>
      <Stack.Navigator
        screenOptions={{
          header: ({ navigation, route, options, back }) => {
            return (
              <StackHeader
                containerStyle={styles.containerStyle}
                safe={true}
                route={route} options={options} back={back}
              />
            )
          },
        }}
      >
        <Stack.Screen name={'Repair'} component={Repair} options={{ title: 'Results' }} />
        <Stack.Screen name={'Technique'} component={Technique} />
        <Stack.Screen name={'Precautions'} component={Precautions} />
        <Stack.Screen name={'Note'} component={Note} options={{ title: 'Procedure Note'}} />
        <Stack.Screen name={'Browser'} component={Browser} />
      </Stack.Navigator>
    </NavigationContainer>
  )
})

export default RepairStack