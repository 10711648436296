import React, { useMemo } from 'react'
import { useColorScheme, Image as RNImage } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { imageList } from 'components/TaxonomySelector'
import { Theme } from 'styles'


const Image = observer((props) => {

  const { rootStore } = useStores()
  const { appStore } = rootStore
  const scheme = useColorScheme()
  const { slug, taxonomy, isSelected } = props

  // Load local asset or remote if not found
  const getImage = useMemo(() => {
    if (imageList[taxonomy] && imageList[taxonomy][slug]) {
      let images = imageList[taxonomy][slug]
      if (!isSelected) {
        return scheme === 'light' ? images.light : images.dark
      } else {
        return scheme === 'light' ? images.dark : images.light
      }
    } else {
      if (item.image.light && item.image.dark) {
        if (!isSelected) {
          return scheme === 'light' ? {uri: item.image.light} : {uri: item.image.dark}
        } else {
          return scheme === 'light' ? {uri: item.image.dark} : {uri: item.image.light}
        }
      }
    }
  }, [scheme, isSelected])

  if (getImage) {
    return (
      <RNImage source={getImage} style={{width: 60, height: 40, marginBottom: Theme.spacing.thin}} />
    )
  } else return false

})

export default Image