import React, { useMemo } from 'react'
import { StyleSheet, View, Pressable, useWindowDimensions } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useNavigation } from '@react-navigation/native'
import { remove } from 'lodash'
import { Text, Button, Message } from 'components'
import { Repair, Patient, Prophylaxis } from 'components/Recommendations'
import { Theme } from 'styles'

const Recommendation = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const navigation = useNavigation()
  const width = useWindowDimensions().width
  const { recommendation } = props

  const styles = StyleSheet.create({
    wrap: {},
  })

  switch (recommendation.repair_type) {

    case 'do_not_repair':
      return (
        <View>
          <Message 
            title={'Do not repair'}
            message={`Warning: this laceration requires a specialist, consult ${recommendation.consultant}.`}
            icon={'warning-sharp'}
            background={'errorLight'}
            containerStyle={{marginBottom: Theme.spacing.layout}}
          />
          <Patient recommendation={recommendation} />
          <Prophylaxis recommendation={recommendation} />
        </View>
      )
    break

    default:
      return (
        <View>
          <Repair recommendation={recommendation} />
          <Patient recommendation={recommendation} />
          <Prophylaxis recommendation={recommendation} />
        </View>
      )
  }

})

export default Recommendation