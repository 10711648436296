import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useNavigation } from '@react-navigation/native'
import { Text, Icon, Button } from 'components'
import { Removal, Precautions, FollowUp } from 'components/Recommendations'
import { Theme, themedRecommendations } from 'styles'

const Patient = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const navigation = useNavigation()

  const { recommendation } = props

  return (
    <View style={styles.module}>

      <View style={styles.title}>
        <Icon size={'h3'} name={'person-sharp'} /> 
        <Text size={'h3'} style={styles.titleText}>Patient Instructions</Text>
      </View>

      <Removal recommendation={recommendation} />

      <Precautions recommendation={recommendation} />

      <FollowUp recommendation={recommendation} />

      <View style={styles.footer}>
        <Button 
          size={'small'}
          icon={'chevron-forward-sharp'}
          text={'View Instructions'}
          onPress={() => navigation.push('Precautions', {id: recommendation.precautions})}
        />
      </View>
    </View>
  )
})

export default Patient