const sutureapp = {
  development: {
  	domain: 'https://www.suture.app',
  	api: '/wp-json/wp/v2',
  },
  production: {
  	domain: 'https://www.suture.app',
  	api: '/wp-json/wp/v2',
  },
}

const alert = {
  development: {
    domain: 'http://localhost:8080',
    api: '/api',
  },
  production: {
    domain: 'https://alert.suture.app',
    api: '/api',
  }
}

export {
	sutureapp,
  alert,
}