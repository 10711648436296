import React from 'react'
import { configure } from 'mobx'
import { AppStore } from 'stores/app'
import { ContentStore } from 'stores/content'
import { PickerStore } from 'stores/picker'
import { ModalStore } from 'stores/modal'
import { ScrollStore } from 'stores/scroll'

configure({ enforceActions: 'never' })

class RootStore {
  constructor() {
    this.appStore = new AppStore(this)
    this.contentStore = new ContentStore(this)
    this.pickerStore = new PickerStore(this)
    this.modalStore = new ModalStore(this)
    this.scrollStore = new ScrollStore(this)
  }
}

const rootStore = new RootStore()

export const storesContext = React.createContext({
	rootStore: rootStore,
})

export const useStores = () => React.useContext(storesContext)