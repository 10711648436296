import { makeAutoObservable, observable, toJS } from 'mobx'
import { makePersistable, isHydrated } from 'mobx-persist-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import pluralize from 'pluralize'
import { findIndex, isEqual } from 'lodash'
import { appStoreDebug } from 'debug/settings'

export class AppStore {

  locations = []
  taxonomy = [
    { title: 'depth', value: null },
    { title: 'shape', value: null },
    { title: 'complication', value: null },
    { title: 'contamination', value: null },
    { title: 'immunization', value: null },
  ]
  repairIndex = 0
  firstLaunch = true
  templateString = '***'
  defaultEmail = ''

  constructor(rootStore) {
    this.rootStore = rootStore
		makeAutoObservable(this)
    makePersistable(this, {
      name: 'AppStore',
      properties: ['firstLaunch', 'templateString', 'defaultEmail'],
      storage: AsyncStorage,
    })
  }

  setLocations(locations) {
    this.locations = locations
  }

  get locationsSet() {
    switch (this.locations.length) {
      case 0:
        return false
        break
      case 1:
        return this.rootStore.contentStore.lacerations.find((laceration) => {
          return isEqual(laceration.locations, this.locations) && !laceration.shell
        })
      case 2:
        return true
        break
    }
  }
  
  get anyTaxonomySet() {
    return this.taxonomy.some((taxonomy) => {
      return taxonomy.value !== null
    })
  }

  getSingular(taxonomy) {
    return pluralize.singular(taxonomy)
  }

  getPlural(taxonomy) {
    return pluralize(taxonomy)
  }

  getTaxonomy(taxonomy) {
    return this.taxonomy.find(item => item.title == this.getSingular(taxonomy)).value
  }

  setTaxonomy(taxonomy, id) {
    this.taxonomy.find(item => item.title == this.getSingular(taxonomy)).value = id
    this.rootStore.scrollStore.scrollToNextTaxonomy(this.getNextTaxonomyTitle(taxonomy))
  }

  toggleTaxonomy(taxonomy, id) {
    this.resetRepairIndex()
    if (this.getTaxonomy(taxonomy) === id) {
      this.resetTaxonomy(taxonomy)
    } else {
      this.resetTaxonomy(taxonomy)
      this.setTaxonomy(taxonomy, id)
    } 
  }

  // Reset selected and subsequent taxonomy items to null
  resetTaxonomy(taxonomy) {
    let index = this.getTaxonomyIndex(taxonomy)
    this.taxonomy.slice(index).map(item => item.value = null)
  }

  // Get previous taxonomy
  getPreviousTaxonomyTitle(taxonomy) {
    let previousIndex = this.getTaxonomyIndex(taxonomy) - 1
    if (previousIndex > 0) {
      return this.taxonomy[previousIndex].title
    }
  }

  // Get next taxonomy
  getNextTaxonomyTitle(taxonomy) {
    let nextIndex = this.getTaxonomyIndex(taxonomy) + 1
    if (nextIndex < this.taxonomy.length) {
      return this.taxonomy[nextIndex].title
    }
  }

  // Get the taxonomy index in array
  getTaxonomyIndex(taxonomy) {
    return this.taxonomy.findIndex(item => item.title === this.getSingular(taxonomy))
  }

  // Check if taxonomy selection is complete
  get selectionComplete() {
    return this.hasTaxonomyAndParentTaxonomySet('contaminations')
  }

  // Check if selections have been made up to current taxonomy among available options for current laceration
  hasTaxonomyAndParentTaxonomySet(taxonomy) {
    // Get index of passed taxonomy
    let index = this.getTaxonomyIndex(taxonomy)

    // Slice taxonomy array to the passed taxonomy
    let availableTaxonomy = this.taxonomy.slice(0, index)

    // Filter sliced array to include only taxonomies which are available for the current laceration
    let filteredAvailableTaxonomy = availableTaxonomy.filter(item => {
      return this.rootStore.contentStore.currentLacerationHasTaxonomy(this.getPlural(item.title))
    })

    // Current taxonomy is the first presented and has no parent so return true
    if (!filteredAvailableTaxonomy.length) {
      return true
    }
    // Return true if each taxonomy item has been set, checking array length because every returns vacuously true
    else if (filteredAvailableTaxonomy.length) {
      return filteredAvailableTaxonomy.every(item => item.value)
    } else return false
  }

  introComplete() {
    this.firstLaunch = false
  }

  setTemplateString(string) {
    this.templateString = string
  }

  setDefaultEmail(email) {
    this.defaultEmail = email
  }

  setRepairIndex(index) {
    this.repairIndex = index
  }

  resetRepairIndex() {
    this.repairIndex = 0
  }

  get isHydrated() {
    return isHydrated(this)
  }

  loadDebug() {
    this.locations = appStoreDebug.locations
    this.taxonomy = appStoreDebug.taxonomy
    this.firstLaunch = appStoreDebug.firstLaunch
  }

  reset() {
    this.locations = []
    this.resetDetails()
  }

  resetDetails() {
    this.resetRepairIndex()
    this.taxonomy = [
      { title: 'depth', value: null },
      { title: 'shape', value: null },
      { title: 'complication', value: null },
      { title: 'contamination', value: null },
      { title: 'immunization', value: null },
    ]
  }

}