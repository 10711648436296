import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Icon } from 'components'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Message = ({
	title = null,
  message= null,
	icon = null,
  iconFamily = 'Ionicon',
	disabled = false,
	containerStyle = styles.container,
  titleStyle = styles.title,
	messageStyle = styles.message,
	iconStyle = styles.icon,
  background = 'light'
}) => {
  const { colors } = useTheme()

  return (
    <View style={[
      styles.container,
      { backgroundColor: colors[background] },
      containerStyle
    ]}>
      {icon &&
        <View style={styles.iconWrap}>
          <Icon name={icon} family={iconFamily} style={styles.icon} />
        </View>
      }
      <View style={styles.messageWrap}>
        {title && <Text style={styles.title}>{title}</Text>}
        <Text style={styles.message}>{message}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
	container: {
	  flexDirection: 'row',
    padding: Theme.spacing.layout,
	},
  iconWrap: {
    paddingRight: Theme.spacing.thin,
  },
  icon: {
    fontSize: Theme.font.size.h1,
  },
  messageWrap: {
    flex: 1,
  },
  title: {
    fontFamily: Theme.font.family.bold,
    fontSize: Theme.font.size.h2,
  },
  message: {
    fontSize: Theme.font.size.p
  },
})

export default Message