import React, { useState } from 'react'
import { StyleSheet, View, Pressable, Image } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import Collapsible from 'react-native-collapsible'
import { Text, Icon } from 'components'
import { Buttons } from 'components/ProphylaxisSelector'
import { startCase } from 'lodash'
import pluralize from 'pluralize'
import { Theme } from 'styles'

const ProphylaxisSelector = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, scrollStore } = rootStore
	const { colors } = useTheme()
  const [ collapsed, setCollapsed ] = useState(true)

  const { taxonomy } = props
  
  const handleLayout = ({nativeEvent}) => {
    scrollStore.setCoord(taxonomy, nativeEvent.layout.y)
  }

  const handleToggle = () => {
    setCollapsed(!collapsed)
  }

  const handleAnimationEnd = () => {
    if (!collapsed) {
      scrollStore.scrollToNextTaxonomy(taxonomy)
    }
  }

  const styles = StyleSheet.create({
    wrap: {
      marginTop: Theme.spacing.layout,
    },
    collapseHeader: {
      flexDirection: 'row',
    },
    collapseText: {
      flex: 1,
    },
    collapseIcon: {
      fontSize: Theme.font.size.h2,
      marginRight: Theme.spacing.layout,
    },
    options: {},
  })

  return (
    <View
      style={styles.wrap}
      onLayout={handleLayout}
    >
      <Pressable style={styles.collapseHeader} onPress={handleToggle}>
        <Text style={styles.collapseText} size={'h2'} weight={'bold'}>{contentStore.getTaxonomyTitle(taxonomy)}</Text>
        <Icon style={styles.collapseIcon} name={collapsed ? 'chevron-down-sharp' : 'chevron-up-sharp'} />
      </Pressable>
      <Collapsible 
        collapsed={collapsed}
        onAnimationEnd={handleAnimationEnd}
      >
        <View style={styles.options}>
          <Buttons taxonomy={taxonomy} />
        </View>
      </Collapsible>
    </View>
  )

})

export default ProphylaxisSelector