import React, { useState } from 'react'
import { View, StyleSheet, ActivityIndicator, useWindowDimensions } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme, useNavigation } from '@react-navigation/native'
import { WebView } from 'react-native-webview'
import { Text } from 'components'
import { Theme } from 'styles'

const Browser = observer((props) => {

	const { colors } = useTheme()
  const navigation = useNavigation()
  const { uri } = props.route.params
  const { width } = useWindowDimensions()
  const [ progress, setProgress ] = useState(0.01)

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    webView: {},
		loading: {
			...StyleSheet.absoluteFillObject,
			backgroundColor: colors.background+Theme.opacity.translucent,
			alignItems: 'center',
			justifyContent: 'center',
		},

    progressWrap: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      width: width,
    },
    progress: {
      width: width*progress,
      flex: 1,
      height: 2,
      backgroundColor: colors.accent,
    },
  })

  const progressIndicator = () => {
    if (progress < 1) {
      return (
        <View style={styles.progressWrap}>
          <View style={styles.progress} />
        </View>
      )
    }
  }

	const handleMessage = (message) => {
		navigation.setOptions({
			title: message.nativeEvent.data
		})
	}

  return (
    <View style={styles.wrap}>
      {progressIndicator()}
      <WebView 
        onLoadProgress={({ nativeEvent }) => {
          setProgress(nativeEvent.progress)
        }}
        injectedJavaScript={'window.ReactNativeWebView.postMessage(document.title)'}
        onMessage={handleMessage}
        style={styles.webView}
        source={{ uri: uri }}
      />
    </View>
  )
})

export default Browser