import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { Theme, themedRecommendations } from 'styles'

const Removal = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const { recommendation } = props
  const removal_interval = recommendation.removal_interval

  if (removal_interval?.minimum) {
    return (
      <View style={styles.content}>
        <Text size={'h3'} weight={'bold'}>Removal Interval</Text>
        <Text size={'h3'}>
          {removal_interval.minimum} {removal_interval.maximum && ` - ${removal_interval.maximum}`} days
        </Text>
      </View>
    )
  } else return false
})

export default Removal