import * as Sentry from 'sentry-expo'

const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://711d0154a4cf4ff88ff8acb8869c0691@o1326019.ingest.sentry.io/6585690'
  })
}

export {
  initializeSentry,
}