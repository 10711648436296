import { makeAutoObservable } from 'mobx'

export class ScrollStore {

  scroll = null
  coords = [
    { title: 'depth', value: 0 },
    { title: 'shape', value: 0 },
    { title: 'complication', value: 0 },
    { title: 'contamination', value: 0 },
    { title: 'immunization', value: 0 },
  ]

  constructor(rootStore) {
    this.rootStore = rootStore
		makeAutoObservable(this)
  }

  setScrollRef(scroll) {
    this.scroll = scroll.current
  }

  setCoord(taxonomy, coord) {
    let title = this.rootStore.appStore.getSingular(taxonomy)
    this.coords.find(item => item.title == title).value = coord
  }

  getCoord(taxonomy) {
    let title = this.rootStore.appStore.getSingular(taxonomy)
    return this.coords.find(item => item.title == title).value
  }

  scrollToTop() {
    this.scroll.scrollTo({
      y: 0,
    })
  }

  scrollToNextTaxonomy(taxonomy) {
    if (taxonomy) {
      this.scroll.scrollTo({
        y: this.getCoord(taxonomy),
      })
    }
  }
}