import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Message } from 'components'
import { Theme, themedRecommendations } from 'styles'

const Notice = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  if (!appStore.locationsSet) {
    return (
      <Message 
        title={'Select a location'}
        message={'You’ll start getting recommendations after selecting a location and sublocation.'}
        icon={'location-sharp'}
        containerStyle={styles.notice}
      />
    )
  }
  else if (!contentStore.filteredRepairs) {
    return (
      <Message
        title={'No Recs'}
        message={'We don’t have any recomendations for the combination you selected.'}
        icon={'cancel'}
        iconFamily={'MaterialCommunityIcons'}
        containerStyle={styles.notice}
      />
    )
  } else return false

})

export default Notice