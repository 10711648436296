export const Theme = {

	spacing: {
		layout: 20,
		thin: 10,
		border: 5,
	},

  layout: {
    maxWidth: 800,
    drawerHeader: {
      height: 100,
    },
  },

  main: {
    web: {},
    native: {
    	color: {
    		light: {
    		  dark: false,
    		  colors: {
    		    primary: '#111111',
    		    background: '#ffffff',
    		    card: '#ffffff',
    		    text: '#111111',
    				textLight: '#999999',
    		    border: '#ffffff',
    		    notification: '#111111',
    				accent: '#b65bff',
    				light: '#eeeeee',
            medium: '#dddddd',
            dark: '#999999',
            error: '#ff5b5b',
            errorLight: '#fff3f3',
            shadow: '#111111',
            bottomSheet: {
              background: '#ffffff',
              backdrop: '#000000',
              handle: '#dddddd',
            },
    		  },
    		},
    		dark: {
    		  dark: true,
    		  colors: {
    		    primary: '#ffffff',
    		    background: '#000000',
    		    card: '#111111',
    		    text: '#ffffff',
    				textLight: '#666666',
    		    border: '#111111',
    		    notification: '#ffffff',
    				accent: '#b65bff',
    				light: '#222222',
            medium: '#333333',
            dark: '#666666',
            error: '#ff5b5b',
            errorLight: '#401f1f',
            shadow: '#000000',
            bottomSheet: {
              background: '#222222',
              backdrop: '#000000',
              handle: '#666666',
            },
    		  },
    		},
      },
    },
	},

  repair: {
    web: {},
    native: {
    	color: {
    		light: {
    		  dark: false,
    		  colors: {
    		    primary: '#111111',
    		    background: '#ffffff',
    		    text: '#111111',
    				accent: '#b65bff',
    				light: '#eeeeee',
            medium: '#dddddd',
            dark: '#999999',
            error: '#ff5b5b',
            errorLight: '#fff3f3',
            shadow: '#000000',
    		  },
    		},
    		dark: {
    		  dark: true,
    		  colors: {
    		    primary: '#ffffff',
    		    background: '#222222',
    		    text: '#ffffff',
    				accent: '#b65bff',
    				light: '#111111',
            medium: '#333333',
            dark: '#666666',
            error: '#ff5b5b',
            errorLight: '#401f1f',
            shadow: '#000000',
    		  },
    		},
      },
    },
  },

	opacity: {
		translucent: '1a',    // 10%
		medium: '80',         // 50%
		opaque: 'cc',         // 80%
	},

	font: {
		family: {
			thin: 'Poppins_100Thin',
			light: 'Poppins_300Light',
			regular: 'Poppins_400Regular',
			medium: 'Poppins_500Medium',
			semibold: 'Poppins_600SemiBold',
			bold: 'Poppins_700Bold',
		},
		size: {
			h1: 38,
			h2: 24,
			h3: 18,
			p: 16,
			sm: 13,
		},
	},

}