import React, { useEffect, useRef, useState, useMemo } from 'react'
import { StyleSheet, SafeAreaView, FlatList, View } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme, useNavigation } from '@react-navigation/native'
import { startCase, join } from 'lodash'
import { useStores } from 'stores'
import { Text, ScrollView, TextInput, HTMLRenderer, Button, SendButton } from 'components'
import { Theme } from 'styles'
import { send } from 'utils'
import Handlebars from 'handlebars/lib/handlebars'

const Note = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, modalStore } = rootStore
	const { colors } = useTheme()
  const navigation = useNavigation()

  const repair = contentStore.filteredRepairs
  const recommendation = repair.recommendations[appStore.repairIndex]
  const [ email, setEmail ] = useState(appStore.defaultEmail)
  const [ status, setStatus ] = useState(null)

  useEffect(function popStack() {
    if (modalStore.isClosed) {
      navigation.popToTop()
    }
  }, [modalStore.index])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
    },

    options: {
      marginHorizontal: Theme.spacing.layout,
    },

    header: {
      marginBottom: Theme.spacing.border,
    },
    description: {},

    tabWrap: {
      marginTop: Theme.spacing.border,
    },
    tab: {
      backgroundColor: colors.background,
    },
    tabActive: {
      backgroundColor: colors.light,
    },
    tabText: {
      flex: 0,
      color: colors.text,
    },
    tabActiveText: {},

    input: {
      marginBottom: Theme.spacing.layout,
      fontSize: Theme.font.size.h3,
      fontFamily: Theme.font.family.regular,
      padding: Theme.spacing.thin,
      backgroundColor: colors.light,
      color: colors.text,
    },

    preview: {
      flex: 1,
      margin: Theme.spacing.layout,
    },
    scrollView: {
      padding: Theme.spacing.layout,
      backgroundColor: colors.light,
    },

    sendWrap: {
      marginHorizontal: Theme.spacing.layout,
      marginBottom: Theme.spacing.layout,
    },
    sendButtonWrap: {
      flexDirection: 'row',
    },
    sendButton: {
      flex: 3,
    },
    statusButton: {
      flex: 0,
    },

  })

  const handleSend = async () => {
    try {
      setStatus('loading')
      const message = await send('note', {
        email: email,
        note: note,
      })
      setStatus('success')
    }
    catch (error) {
      setStatus('error')
    }
  }

  const handleReset = () => {
    setStatus(null)
  }

  const handleChangeTemplateString = (string) => {
    appStore.setTemplateString(string)
  }

  const buildTime = useMemo(() => {
    let date = new Date()
    let format = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute:'2-digit',
    }
    return date.toLocaleString([], format)
  })

  const buildLocationString = useMemo(() => {
    let matchingTitles = appStore.locations.map((locationId) => {
      return contentStore.locations.find((location) => {
        return location.id === locationId
      }).title
    })
    return join(matchingTitles, ', ')
  }, [])

  const buildTaxonomyString = (taxonomy) => {
    let matchingTaxonomy = contentStore[taxonomy].find((item) => {
      return item.id === appStore.getTaxonomy(taxonomy)
    })
    return matchingTaxonomy?.title
  }

  const buildTechniqueString = useMemo(() => {
    let matchingTechniques = recommendation.techniques.map((selectedTechnique) => {
      return contentStore.techniques.find((technique) => {
        return technique.id === selectedTechnique.id
      }).title
    })
    return join(matchingTechniques, ', ')
  }, [])

  const buildClosureObject = useMemo(() => {
    switch (recommendation.repair_type) {
      case 'suture':
        if (recommendation.suture.deep.type) {
          return `${appStore.templateString} superficial sutures (${recommendation.suture.superficial.size}-0 ${recommendation.suture.superficial.type}) and ${appStore.templateString} deep sutures ${recommendation.suture.deep.size}-0 ${recommendation.suture.deep.type}`
        }
        else {
          return `${appStore.templateString} sutures (${recommendation.suture.superficial.size}-0 ${recommendation.suture.superficial.type})`
        }
      break
      case 'staple':
        return `${appStore.templateString} staples`
      break
      default: 
        return false
      break
    }
  })

  const buildComplexityString = useMemo(() => {
    return recommendation.techniques.length > 1 ? 'Complex' : 'Simple'
  })

  let template = Handlebars.compile(contentStore.notes[0].content)
  let variables = {
    now: buildTime,
    location: buildLocationString,
    templateString: appStore.templateString,
    shape: buildTaxonomyString('shapes'),
    depth: buildTaxonomyString('depths'),
    complication: buildTaxonomyString('complications'),
    contamination: buildTaxonomyString('contaminations'),
    technique: buildTechniqueString,
    closure: buildClosureObject,
    complexity: buildComplexityString,
  }
  let note = template(variables)

  return (
    <SafeAreaView style={styles.wrap}>

      <View style={styles.options}>
        <Text size={'h3'} weight={'bold'} style={styles.header}>Options</Text>
        <Text style={styles.description}>Select the string of characters you want to use for fields that require your input.</Text>
        <FlatList
          data={[
            { title: 'Asterisk', string: '***' },
            { title: 'Underscore', string: '_' },
          ]}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.title}
          renderItem={({item}) => {
            let isActive = appStore.templateString === item.string
            let text = item.title + ( item.string ? ` (${item.string})` : '')
            return (
              <Button
                text={text}
                size={'small'}
                style={[
                  styles.tab,
                  isActive && styles.tabActive,
                ]}
                textStyle={[
                  styles.tabText,
                  isActive && styles.tabActiveText,
                ]}
                onPress={() => handleChangeTemplateString(item.string)}
              />
            )
          }}
          contentContainerStyle={styles.tabWrap}
        />
      </View>


      <View style={styles.preview}>
        <Text size={'h3'} weight={'bold'} style={styles.header}>Preview</Text>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <HTMLRenderer html={note} />
        </ScrollView>
      </View>

      <View style={styles.sendWrap}>
        <Text size={'h3'} weight={'bold'} style={styles.header}>Send by Email</Text>
        <TextInput 
          style={styles.input}
          autoComplete={'email'}
          autoCorrect={false}
          autoFocus={false}
          autoCapitalize={'none'}
          placeholder={'Email address'}
          placeholderTextColor={colors.dark}
          defaultValue={appStore.defaultEmail}
          keyboardType={'email-address'}
          onChangeText={setEmail}
          returnKeyType={'send'}
          textContentType={'emailAddress'}
          onSubmitEditing={handleSend}
        />
        <SendButton
          onSubmit={handleSend}
          onReset={handleReset}
          status={status}
        />
      </View>
    </SafeAreaView>
  )
})

export default Note