import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { SafeAreaView, Pressable, View, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme, useIsFocused } from '@react-navigation/native'
import { useDrawerStatus } from '@react-navigation/drawer'
import * as Linking from 'expo-linking'
import { useStores } from 'stores'
import { RepairStack } from 'screens'
import { LacerationSelector, Text, Button } from 'components'
import { Theme } from 'styles'

const Home = observer(() => {

  const { rootStore } = useStores()
  const { appStore, contentStore, modalStore, pickerStore, scrollStore } = rootStore
	const { colors } = useTheme()
  const { width, height } = useWindowDimensions()
  const drawer = useDrawerStatus()
  const focused = useIsFocused()

  const scrollRef = useRef(null)
  useEffect(function storeScrollRef() {
    scrollStore.setScrollRef(scrollRef)
  }, [scrollRef])

  const handleDismissPicker = () => {
    pickerStore.dismissAll()
  }

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      flexDirection: 'row',
      marginTop: Theme.layout.drawerHeader.height,
    },
    notice: {
      marginTop: Theme.layout.drawerHeader.height,
      flex: 1,
    },
    noticeContent: {
      padding: Theme.spacing.layout,
    },
    noticeButton: {
      marginTop: Theme.spacing.layout,
    },
    scrollViewContainer: {
      flexGrow: 1,
    },
    lacerationSelector: {
      padding: Theme.spacing.layout,
      paddingTop: 0,
    },
    bottomSheet: {
      shadowColor: colors.shadow,
      shadowOffset: {
      	width: 0,
      	height: 15,
      },
      shadowOpacity: 0.6,
      shadowRadius: 20,
      elevation: 24,
    },
    bottomSheetBackground: {
      borderRadius: 0,
      backgroundColor: colors.background,
    },
    repair: {
      width: '40%',
    }
  })

  if (width < Theme.layout.maxWidth) {
    return (
      <SafeAreaView style={styles.notice}>
        <View style={styles.noticeContent}>
          <Text size={'h1'} weight={'bold'}>Suture.app has an app!</Text>
          <Text>Your screen is a bit too small, please download the free mobile app for a better experience.</Text>
          <Button
            style={styles.noticeButton}
            text={'Download for iOS'}
            onPress={() => Linking.openURL('https://apps.apple.com/us/app/suture-laceration-repair-app/id1619032671')}
          />
          <Button
            style={styles.noticeButton}
            text={'Download for Android'}
            onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.sutureapp')}
          />
        </View>
      </SafeAreaView>
    )
  } else {
    return (
      <SafeAreaView style={styles.wrap}>
        <ScrollView
          ref={scrollRef}
          contentContainerStyle={styles.scrollViewContainer}
          onScrollBeginDrag={handleDismissPicker}
        >
          <Pressable style={styles.lacerationSelector} onPress={handleDismissPicker}>
            <LacerationSelector />
          </Pressable>
        </ScrollView>
        <View style={styles.repair}>
          <RepairStack />
        </View>
      </SafeAreaView>
    )
  }
})

export default Home