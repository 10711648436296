import { initializeApp } from 'firebase/app'
import * as Analytics from 'expo-firebase-analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyBc8hEdKJWiRMqCHbsZse2D3SeAkAcZPZc',
  authDomain: 'suture-app-c7c63.firebaseapp.com',
  projectId: 'suture-app-c7c63',
  storageBucket: 'suture-app-c7c63.appspot.com',
  messagingSenderId: '167248918849',
  appId: '1:167248918849:web:f17346c25af509740809c0',
  measurementId: 'G-G1NLJH6893'
}

const initializeFirebase = () => {
  initializeApp(firebaseConfig)
}

const logEvent = async (event, details) => {
  await Analytics.logEvent(event, details)
}

export {
  initializeFirebase,
  logEvent,
}