const iconList = {
  superficial: {
    light: require('assets/images/icon-superficial.png'),
    dark: require('assets/images/icon-superficial-dark.png')
  },
  deep: {
    light: require('assets/images/icon-deep.png'),
    dark: require('assets/images/icon-deep-dark.png')
  },
}

const getIcon = (name, scheme) => {
  return scheme === 'light' ? iconList[name].light : iconList[name].dark
}

export {
  iconList,
  getIcon,
}