import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { apiConnect, fetchOptions } from 'utils/api'
import { sortBy, isEqual } from 'lodash'
import { contentStoreDebug } from 'debug/settings'

export class ContentStore {

  loading = true
  refreshing = false
  progress = 0
  error = false
  pages = []
  lacerations = []
  locations = []
  repairs = []
  techniques = []
  taxonomies = []
  depths = []
  shapes = []
  complications = []
  precautions = []
  prophylaxes = []
  contaminations = []
  immunizations = []
  notes = []
  

  constructor(rootStore) {
    this.rootStore = rootStore
		makeAutoObservable(this)
  }

  async loadContent(refresh) {

    if (refresh) {
      this.loading = false
      this.refreshing = true
      await apiConnect.clearCache()
    } else {
      this.loading = true
    }

    let asyncContent = [
      { object: 'pages', function: apiConnect.get('pages', fetchOptions()) },
      { object: 'lacerations', function: apiConnect.get('lacerations', fetchOptions()) },
      { object: 'locations', function: apiConnect.get('locations', fetchOptions()) },
      { object: 'repairs', function: apiConnect.get('repairs', fetchOptions({ getMaximum: true })) },
      { object: 'techniques', function: apiConnect.get('techniques', fetchOptions({ acfFormat: true })) },
      { object: 'taxonomies', function: apiConnect.get('taxonomies', fetchOptions()) },
      { object: 'depths', function: apiConnect.get('depths', fetchOptions({ acfFormat: true })) },
      { object: 'shapes', function: apiConnect.get('shapes', fetchOptions({ acfFormat: true })) },
      { object: 'complications', function: apiConnect.get('complications', fetchOptions({ acfFormat: true })) },
      { object: 'precautions', function: apiConnect.get('precautions', fetchOptions()) },
      { object: 'prophylaxes', function: apiConnect.get('prophylaxes', fetchOptions()) },
      { object: 'contaminations', function: apiConnect.get('contaminations', fetchOptions({ acfFormat: true })) },
      { object: 'immunizations', function: apiConnect.get('immunizations', fetchOptions()) },
      { object: 'notes', function: apiConnect.get('notes', fetchOptions()) },
    ]

    try {
      for await (const [index, item] of asyncContent.entries()) {
        let content = await item.function
        runInAction(() => {
          this[item.object] = content
          this.progress = (index+1)/asyncContent.length
        })
      }

      runInAction(() => {
        this.error = false
        this.loading = false
        this.refreshing = false
        this.progress = 0
      })
    }

    catch (error) {
      runInAction(() => {
        this.error = true
        this.loading = false
        this.refreshing = false
        this.progress = 0
  			console.log(error)
      })
    }
  }

  getFilteredTaxonomyObjectsByIds(taxonomy, ids) {
    let filteredTaxonomy = []
    ids.map((id) => {
      let filteredItem = this[taxonomy].find(item => item.id === id)
      filteredTaxonomy.push(filteredItem)
    })

    return sortBy(filteredTaxonomy, ['sort_order'])
  }

  getTaxonomyObjectById(taxonomy, id) {
    return this[taxonomy].find(item => item.id === id)
  }

  getTaxonomyIdBySlug(taxonomy, slug) {
    return this[taxonomy].find(item => item.slug === slug).id
  }

  // Return all lacerations that are parents
  get parentLacerations() {
    let filteredParents = this.lacerations.filter(laceration => {
      return laceration.isParent
    })
    return sortBy(filteredParents, (laceration) => laceration.sort_order)
  }

  // Return all lacerations that are children
  get childLacerations() {
    let filteredChildren = this.lacerations.filter(laceration => {
      return !laceration.isParent
    })
    return sortBy(filteredChildren, (laceration) => laceration.sort_order)
  }

  // Return all lacerations that are children of the selected parent
  get selectedChildLacerations() {
    return this.childLacerations.filter(laceration => {
      return laceration.locations.some(location => this.rootStore.appStore.locations.includes(location))
    })
  }

  // Find laceration that matches selected parent and child combination
  get currentLacerationByLocation() {
    return this.lacerations.find(laceration => {
      return laceration.locations.every(location => {
        if (this.rootStore.appStore.locations.length === laceration.locations.length) {
          return this.rootStore.appStore.locations.includes(location)
        }
      })
    })
  }

  // Get single repair matching all taxonomy selections
  get filteredRepairs() {
    return this.repairs.find((repair) => {
      return (
        isEqual(this.rootStore.appStore.locations, repair.locations) &&
        isEqual(this.rootStore.appStore.getTaxonomy('depths'), repair.depth) &&
        isEqual(this.rootStore.appStore.getTaxonomy('shapes'), repair.shape) &&
        isEqual(this.rootStore.appStore.getTaxonomy('complications'), repair.complication)
      )
    })
  }

  // Check if current laceration has taxonomy options
  currentLacerationHasTaxonomy(taxonomy) {
    return this.currentLacerationByLocation?.[taxonomy]?.length
  }

  getLocationTitleById(id) {
    return this.locations.find(location => location.id === id)?.title
  }

  getLocationIdBySlug(slug) {
    return this.locations.find(location => location.slug === slug)?.id
  }

  getTechniqueById(id) {
    return this.techniques.find(technique => technique.id === id)
  }

  getPrecautionById(id) {
    return this.precautions.find(precaution => precaution.id === id)
  }

  getPageBySlug(slug) {
    return this.pages.find(page => page.slug === slug)
  }

  getChildPagesBySlug(slug) {
		let parent = this.getPageBySlug(slug)
		if (parent?.id) {
			return this.pages.filter(page => {
				return page.parent === parent.id
			})
		}
  }

  getfilteredProphylaxis(type) {
    return this.prophylaxes.filter(prophylaxis => {
      return prophylaxis.type === type
    })
  }

  getTaxonomyTitle(taxonomy) {
    return this.taxonomies.find(item => item.slug === taxonomy)?.title
  }

  loadDebug() {
    this.loading = false
    Object.keys(contentStoreDebug).map((item, index) => {
      let data = contentStoreDebug[item]
      this[item] = data
    })
  }

}