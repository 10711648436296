import React, { useState, useEffect, useMemo } from 'react'
import { StyleSheet, useWindowDimensions, View } from 'react-native'
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html'
import { findOne, textContent, getAttributeValue } from 'domutils'
import { Video as ExpoVideo } from 'expo-av'
import * as ScreenOrientation from 'expo-screen-orientation'
import { Text, Icon } from 'components'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'


const VideoRenderer = (props) => {

	const { colors } = useTheme()
  const { tnode } = props
  const videoWidth = tnode.attributes.width
  const videoHeight = tnode.attributes.height
  const posterSource = tnode.attributes.poster

  const screenWidth = useWindowDimensions().width
  const [ width, setWidth ] = useState(screenWidth)
  const handleLayout = ({ nativeEvent }) => {
    let { width } = nativeEvent.layout
    setWidth(width)
  }

  const videoRatio = useMemo(() => width / videoWidth, [width])
  const scaledVideoWidth = Math.round(videoWidth * videoRatio)
  const scaledVideoHeight = Math.round(videoHeight * videoRatio) + 1
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ fullScreenStatus, setFullScreenStatus ] = useState(null)

  const handlePlaybackStatus = (status) => {
    setIsLoaded(status.isLoaded)
  }

  useEffect(function handleFullScreenUpdate() {
    switch (fullScreenStatus) {
      // PLAYER_WILL_PRESENT
      case 0:
      break

      // PLAYER_DID_PRESENT
      case 1:
        ScreenOrientation.unlockAsync()
      break

      // PLAYER_WILL_DISMISS
      case 2:
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT)
      break

      // PLAYER_DID_DISMISS
      case 3:
      break
    }
  }, [fullScreenStatus])

  const styles = StyleSheet.create({
    wrap: {
      backgroundColor: colors.light,
    },
    titleWrap: {
      flexDirection: 'row',
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
    },
    iconWrap: {
      alignSelf: 'center',
      marginRight: Theme.spacing.border,
    },
    videoWrap: {},
    descriptionWrap: {
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
    },
  })

  const getSource = useMemo(() => {
    let sourceTag = findOne((element) => {
      return element.tagName === 'source'
    }, tnode.domNode.children)

    return sourceTag ? sourceTag.attribs.src : ''
  })

  const getTitle = useMemo(() => {
    let titleTag = findOne((element) => {
      return element.tagName === 'h2'
    }, tnode.domNode.children)

    return titleTag && textContent(titleTag)
  })

  const getDescription = useMemo(() => {
    let descriptionTag = findOne((element) => {
      return element.tagName === 'p'
    }, tnode.domNode.children)

    return descriptionTag && textContent(descriptionTag)
  })

  const getCitation = useMemo(() => {
    let citationTag = findOne((element) => {
      return element.tagName === 'cite'
    }, tnode.domNode.children)

    if (citationTag) {
      let citationLink = getAttributeValue(citationTag, 'href')
      return {
        text: textContent(citationTag),
        link: citationLink
      }
    } else return false
  })

  return (
    <View style={styles.wrap} onLayout={handleLayout}>
      {getTitle && 
        <View style={styles.titleWrap}>
          <View style={styles.iconWrap}>
            <Icon name={'videocam-sharp'} size={'h3'} />
          </View>
          <Text size={'h3'} weight={'bold'}>{getTitle}</Text>
        </View>
      }
      <View style={styles.videoWrap}>
        <ExpoVideo
          source={{uri: getSource}}
          posterSource={{uri: posterSource}}
          usePoster={!isLoaded}
          useNativeControls={true}
          resizeMode={'contain'}
          style={{width: scaledVideoWidth, height: scaledVideoHeight}}
          onPlaybackStatusUpdate={handlePlaybackStatus}
          onFullscreenUpdate={({fullscreenUpdate}) => setFullScreenStatus(fullscreenUpdate)}
        />
      </View>
      {getDescription && 
        <View style={styles.descriptionWrap}>
          <Text size={'sm'}>{getDescription}</Text>  
          {getCitation && <Text size={'sm'}>Video from: {getCitation.text}</Text>}
        </View>
      }

    </View>
  )
}

export default VideoRenderer