import React, { useMemo } from 'react'
import { useColorScheme, useWindowDimensions, SafeAreaView, View, Image, StyleSheet } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@react-navigation/native'
import { useStores } from 'stores'
import { Text } from 'components'
import { Theme } from 'styles'
import { useFonts, logEvent, getTheme } from 'utils'

const ContentLoading = observer(() => {

  const { rootStore } = useStores()
  const { contentStore } = rootStore
  const scheme = useColorScheme()
  const { colors } = useMemo(() => getTheme(scheme), [scheme])
  const { width, height } = useWindowDimensions()
  

  const imagePath = useMemo(() => {
    let light = require('assets/icon.png')
    let dark = require('assets/icon-dark.png')
    return scheme === 'light' ? light : dark
  }, [scheme])

  const imageSize = useMemo(() => {
    return width/2
  }, [width])

  const progressWidth = width - (Theme.spacing.layout * 4)
  const progressCompleteWidth = useMemo(() => {
    return progressWidth*contentStore.progress
  }, [contentStore.progress])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.background,
    },
    logoWrap: {},
    logo: {
      width: imageSize,
      maxWidth: 300,
      height: imageSize,
      maxHeight: 300,
    },
    progressBar: {
      backgroundColor: colors.light,
      width: progressWidth, height: 2,
    },
    progressComplete: {
      backgroundColor: colors.accent,
      width: progressCompleteWidth, height: 2,
    },
  })

  return (
    <SafeAreaView style={styles.wrap}>
      <View style={styles.logoWrap}>
        <Image source={imagePath} style={styles.logo} />
      </View>
      <View style={styles.progressBar}>
        <View style={styles.progressComplete} />
      </View>
    </SafeAreaView>
  )
})

export default ContentLoading