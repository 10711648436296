import React, { useState, useMemo, useRef } from 'react'
import { StyleSheet, View, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Text, Button, Icon } from 'components'
import { Theme } from 'styles'

const Settings = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
  const { bottom } = useSafeAreaInsets()
	const { colors } = useTheme()

  const [ email, setEmail ] = useState()
  const [ editable, setEditable ] = useState(!appStore.defaultEmail)
  const emailInput = useRef(null)

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    scrollView: {
      maxWidth: Theme.layout.maxWidth,
      paddingTop: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      paddingBottom: Theme.spacing.layout + bottom,
    },
    settingsGroup: {
      backgroundColor: colors.light,
      padding: Theme.spacing.layout,
      marginBottom: Theme.spacing.layout,
      borderRadius: Theme.spacing.border,
    },

    error: {
      backgroundColor: colors.error + Theme.opacity.opaque,
    },
    message: {
      flexDirection: 'row',
    },
    messageIcon: {
      marginRight: Theme.spacing.thin,
      color: colors.background,
    },
    messageText: {
      flex: 1,
      color: colors.background,
    },

    title: {},
    content: {
      paddingVertical: Theme.spacing.thin,
    },
    form: {},
    input: {
      marginBottom: Theme.spacing.layout,
      fontSize: Theme.font.size.h3,
      fontFamily: Theme.font.family.regular,
      padding: Theme.spacing.thin,
      backgroundColor: colors.medium,
      color: colors.text,
    },
    inputEditable: {
      backgroundColor: colors.background,
    },
    footer: {},
  })

  const handleRefresh = () => {
    contentStore.loadContent(true)
  }

  const handleSaveEmail = () => {
    appStore.setDefaultEmail(email)
    setEditable(false)
  }

  const handleMakeEditable = () => {
    setEditable(true)
  }

  const renderFormButtons = () => {
    if (!editable) {
      return (
        <Button text={'Edit'} onPress={handleMakeEditable} />
      )
    } else {
      return (
        <Button text={'Save'} onPress={handleSaveEmail} />
      )
    }
  }

  return (
    <View style={styles.wrap}>
      <KeyboardAwareScrollView 
        extraHeight={100}
        contentContainerStyle={styles.scrollView}
        keyboardDismissMode={'on-drag'}
      >
        <View style={styles.settingsGroup}>
          <View style={styles.title}>
            <Text size={'h2'} weight={'bold'}>Refresh Content</Text>
          </View>
          <View style={styles.content}>
            <Text>Laceration repaire information is automatically refreshed periodically. To force a refresh, press the button below.</Text>
            <Text style={{color: colors.error, paddingTop: Theme.spacing.border}} weight={'medium'}>Note: This will delete all currently stored laceration information.</Text>
          </View>
          <View style={styles.footer}>
            <Button
              disabled={contentStore.refreshing}
              icon={contentStore.error && 'warning-sharp'}
              text={contentStore.refreshing ? `Loading ${Math.round(contentStore.progress * 100)}%` : 'Refresh'}
              onPress={handleRefresh}
            />
          </View>
        </View>
        <View style={styles.settingsGroup}>
          <View style={styles.title}>
            <Text size={'h2'} weight={'bold'}>Default Email Address</Text>
          </View>
          <View style={styles.content}>
            <Text>You can save your email address as the default for wound recommendations and procedure notes. This email is stored only on your device and is not shared with anyone.</Text>
          </View>
          <View style={styles.form}>
            <TextInput
              ref={emailInput}
              style={[
                styles.input,
                editable && styles.inputEditable,
              ]}
              autoComplete={'email'}
              autoCorrect={false}
              autoFocus={false}
              autoCapitalize={'none'}
              editable={editable}
              placeholder={'Email address'}
              placeholderTextColor={colors.dark}
              blurOnSubmit={true}
              keyboardType={'email-address'}
              defaultValue={appStore.defaultEmail}
              onChangeText={setEmail}
              returnKeyType={'done'}
              textContentType={'emailAddress'}
              onSubmitEditing={handleSaveEmail}
            />
          </View>
          <View style={styles.footer}>
            {renderFormButtons()}
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  )
})

export default Settings