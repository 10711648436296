import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Intro } from 'screens'

const IntroStack = () => {

  const Stack = createNativeStackNavigator()
  const screenOptions = {
    headerShown: false,
  }

  return (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen name={'Intro'} component={Intro} />
    </Stack.Navigator>
  )
}

export default IntroStack