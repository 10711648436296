import { Platform } from 'react-native'

const getPlatform = () => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    return 'native'
  }
  else {
    return 'web'
  }
}

export {
  getPlatform,
}