import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { Theme, themedRecommendations } from 'styles'

const FollowUp = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const { recommendation } = props
  const follow_up = recommendation.follow_up

  return (
    <View style={styles.content}>
      <Text size={'h3'} weight={'bold'}>Follow-Up</Text>
      <Text size={'h3'}>{follow_up}</Text>
    </View>
  )
})

export default FollowUp