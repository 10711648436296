import React, { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import RenderHtml, { HTMLElementModel, HTMLContentModel } from 'react-native-render-html'
import { useTheme, useNavigation } from '@react-navigation/native'
import { VideoRenderer, ImageRenderer, ButtonRenderer } from 'components/HTMLRenderer'
import { Theme } from 'styles'

const HTMLRenderer = (props) => {

	const { colors } = useTheme()
	const { width, height } = useWindowDimensions()
  const navigation = useNavigation()

	const handleLinkPress = (event, href) => {
    navigation.navigate('Browser', { uri: href })
	}

	const systemFonts = [
		Theme.font.family.light, 
		Theme.font.family.regular, 
		Theme.font.family.bold,
	]

	const headerStyles = {
		fontFamily: Theme.font.family.bold,
    marginTop: Theme.spacing.layout,
    marginBottom: Theme.spacing.thin,
	}

  const baseStyle = useMemo(() => {
    return {
			fontFamily: Theme.font.family.regular,
			color: colors.text,
			fontSize: Theme.font.size.p,
    }
  }, [colors])

  const tagsStyles = useMemo(() => {
    return {
  		h1: headerStyles,
  		h2: headerStyles,
  		h3: headerStyles,
  		h4: headerStyles,
  		h5: headerStyles,
  		h6: headerStyles,
      p: {
        marginVertical: Theme.spacing.border,
      },
  		a: { 
        color: colors.accent,
        textDecorationColor: colors.textAccent
      },
      strong: {
        fontFamily: Theme.font.family.bold,
      },
      table: {
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderColor: colors.text,
      },
      td: {
        padding: Theme.spacing.thin,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: colors.text,
      },
      th: {
        backgroundColor: colors.light,
			  fontFamily: Theme.font.family.bold,
        padding: Theme.spacing.thin,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: colors.text,
      },
      hr: {
        marginVertical: Theme.spacing.layout,
        borderWidth: 0,
        borderTopWidth: 2,
        borderColor: colors.text,
      }
    }
  }, [colors])

  const classesStyles = {
    'wp-caption': {
      backgroundColor: colors.light,
      marginBottom: Theme.spacing.thin,
    },
    'wp-caption-text': {
      margin: 0,
      padding: Theme.spacing.thin,
      fontSize: Theme.font.size.sm,
    },
  }

  const handleSource = useMemo(() => {
    return {
      html: props.html
    }
  }, [props.html])

  const renderers = {
    video: VideoRenderer,
    img: ImageRenderer,
    button: ButtonRenderer,
  }

  const customHTMLElementModels = {
    video: HTMLElementModel.fromCustomModel({
      tagName: 'video',
      contentModel: HTMLContentModel.block,
    }),
    source: HTMLElementModel.fromCustomModel({
      tagName: 'source',
      contentModel: HTMLContentModel.text,
    }),
    button: HTMLElementModel.fromCustomModel({
      tagName: 'button',
      contentModel: HTMLContentModel.block,
    }),
  }

  const ignoredDomTags = []

	const renderersProps = {
		a: {
			onPress: handleLinkPress
		},
    ul: {
      markerBoxStyle: { paddingTop: 2, },
    },
    ol: {
      markerBoxStyle: { paddingTop: 4, },
    },
	}

	return (
		<RenderHtml 
			source={handleSource}
			contentWidth={width}
			systemFonts={systemFonts}
			baseStyle={baseStyle}
      customHTMLElementModels={customHTMLElementModels}
      ignoredDomTags={ignoredDomTags}
      renderers={renderers}
			renderersProps={renderersProps}
			tagsStyles={tagsStyles}
      classesStyles={classesStyles}
      enableCSSInlineProcessing={false}
      enableExperimentalMarginCollapsing={true}
		/>
	)
}

export default React.memo(HTMLRenderer)