import { makeAutoObservable } from 'mobx'
import { modalStoreDebug } from 'debug/settings'

export class ModalStore {

  modal = null
  index = 0

  constructor() {
		makeAutoObservable(this)
  }

  setModalRef(modal) {
    this.modal = modal.current
  }

  setIndex(index) {
    this.index = index
  }

  present() {
    this.setIndex(0)
    this.modal.present()
  }

  dismiss() {
    this.modal.dismiss()
  }

  open() {
    this.setIndex(2)
  }

  close() {
    this.setIndex(0)
  }

  get isOpen() {
    return this.modal && this.index === 2
  }

  get isClosed() {
    return this.modal && this.index < 2
  }

  toggle() {
    if (this.index === 0) {
      this.setIndex(1)
    } else {
      this.setIndex(0)
    }
  }

  loadDebug() {
    this.setIndex(modalStoreDebug.index)
  }

}