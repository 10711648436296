import React, { useState } from 'react'
import { StyleSheet, View, Pressable, Image } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { startCase } from 'lodash'
import pluralize from 'pluralize'
import { Buttons, NullButton, Description } from 'components/TaxonomySelector'
import { Theme } from 'styles'

const TaxonomySelector = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, scrollStore } = rootStore
	const { colors } = useTheme()

  const laceration = contentStore.currentLacerationByLocation
  const { taxonomy, showNull } = props

  const handleLayout = ({nativeEvent}) => {
    scrollStore.setCoord(taxonomy, nativeEvent.layout.y)
  }

  const styles = StyleSheet.create({
    wrap: {
      marginTop: Theme.spacing.layout,
    },
    options: {
      flexDirection: 'row',
    },
  })

  if (taxonomy && contentStore.currentLacerationHasTaxonomy(taxonomy)) {
    return (
      <View
        style={styles.wrap}
        onLayout={handleLayout}
      >
        <View style={styles.header}>
          <Text size={'h2'} weight={'bold'}>{pluralize.singular(startCase(taxonomy))}</Text>
        </View>
        <View style={styles.options}>
          {showNull && <NullButton taxonomy={taxonomy} />}
          <Buttons taxonomy={taxonomy} />
        </View>
        <Description taxonomy={taxonomy} />
      </View>
    )
  } else return false

})

export default TaxonomySelector