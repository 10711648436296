import React from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { useTheme, useRoute, useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Text, HTMLRenderer, Button } from 'components'
import { Theme } from 'styles'

const Technique = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()
  const { params } = useRoute()

  const technique = contentStore.getTechniqueById(parseInt(params.id))


  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    scrollView: {
      maxWidth: Theme.layout.maxWidth,
  		paddingTop: Theme.spacing.thin,
      paddingBottom: Theme.spacing.thin + bottom,
      marginHorizontal: Theme.spacing.layout,
    }
  })

  return (
    <View style={styles.wrap}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <HTMLRenderer html={technique.content} />
      </ScrollView>
    </View>
  )
})

export default Technique