import * as Haptics from 'expo-haptics'
import { getPlatform } from 'utils'

const withHapticSelection = async () => {
  if (getPlatform() === 'native') {
    try {
      await Haptics.selectionAsync()
    } catch (error) {
      console.log("Haptic unavailable: ", error)
    }
  } else return false
}

const withHapticImpact = async (
  style='Light'
  ) => {
  if (getPlatform() === 'native') {
    try {
      await Haptics.impactAsync(Haptics.ImpactFeedbackStyle[style])
    } catch (error) {
      console.log("Haptic unavailable: ", error)
    }
  } else return false
}

export {
  withHapticSelection,
  withHapticImpact,
}