import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useNavigation } from '@react-navigation/native'
import { Text, Icon, Button } from 'components'
import { Material, Technique } from 'components/Recommendations'
import { Theme, themedRecommendations } from 'styles'

const Repair = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const navigation = useNavigation()

  const { recommendation } = props

  return (
    <View style={styles.module}>

      <View style={styles.title}>
        <Icon size={'h3'} name={'hammer-sharp'} /> 
        <Text size={'h3'} style={styles.titleText}>Repair Recommendation</Text>
      </View>

      <Material recommendation={recommendation} />

      <Technique recommendation={recommendation} />

      <View style={styles.footer}>
        <Button 
          size={'small'}
          icon={'chevron-forward-sharp'}
          text={'View Technique'}
          onPress={() => navigation.push('Technique', {techniques: recommendation.techniques})}
        />
        <Button 
          size={'small'}
          icon={'document-sharp'}
          text={'Generate Procedure Note'}
          style={{marginTop: Theme.spacing.border}}
          onPress={() => navigation.push('Note', {techniques: recommendation.techniques})}
        />
      </View>
    </View>
  )
})

export default Repair