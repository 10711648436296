import React, { useMemo } from 'react'
import { View, Image, useColorScheme } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { startCase } from 'lodash'
import { Text } from 'components'
import { getIcon } from 'components/Recommendations'
import { Theme, themedRecommendations } from 'styles'

const Material = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const scheme = useColorScheme()
  const styles = themedRecommendations(colors)

  const { recommendation } = props
  const { superficial, deep } = recommendation.suture

  if (recommendation.repair_type === 'suture') {    
    return (
      <View style={styles.content}>
        <Text size={'h3'} weight={'bold'}>{startCase(recommendation.repair_type)}</Text>

        <View style={styles.suture}>
          {deep.type && <Image source={getIcon('superficial', scheme)} style={styles.depthIcon} />}
          <Text size={'h3'}>
            {superficial.size}-0 {superficial.type}
          </Text>
        </View>
      
        {deep.type && 
          <View style={styles.suture}>
            <Image source={getIcon('deep', scheme)} style={styles.depthIcon} />
            <Text size={'h3'}>
              {deep.size}-0 {deep.type}
            </Text>
          </View>
        }
      </View>
    )
  } else return false
})

export default Material