import { StyleSheet } from 'react-native'
import { Theme } from 'styles'

const themedPicker = (colors) => StyleSheet.create({
  wrap: {
    borderWidth: 0,
    borderRadius: 0,
    marginBottom: Theme.spacing.border,
    padding: Theme.spacing.thin,
    backgroundColor: colors.light,
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
  },
  placeholder: {
    backgroundColor: colors.errorLight,
  },
  open: {},
  item: {borderWidth: 0,},
  placeholderText: {color: colors.error},
  text: {
    fontFamily: Theme.font.family.light,
    fontSize: Theme.font.size.h3,
    color: colors.text,
  },
  dropdownContainer: {
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: colors.light,
  },
  dropdownItemContainer: {
    padding: Theme.spacing.thin,
    flexDirection: 'row',
    height: 'auto',
    alignItems: 'center',
  },
  dropdownItemLabelActive: {
    fontFamily: Theme.font.family.medium,
  },
  icon: {
    fontSize: Theme.font.size.h4,
  },
})

export {
  themedPicker
}