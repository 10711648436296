import React from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'
import { useTheme, useNavigation } from '@react-navigation/native'
import { TouchableWithoutFeedback } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Text, Icon } from 'components'
import { Theme } from 'styles'

const DrawerHeader = (props) => {

	const { colors } = useTheme()
  const { navigation, route, options } = props
  const { top } = useSafeAreaInsets()

  const styles = StyleSheet.create({
    wrap: {
      position: 'absolute',
      left: 0, right: 0,
      paddingTop: top,
      height: Theme.layout.drawerHeader.height,
    },
    icon: {
      fontSize: Theme.font.size.h2,
      paddingHorizontal: Theme.spacing.layout,
      paddingVertical: Theme.spacing.thin,
      alignSelf: 'flex-end',
    },
  })

  const handlePress = () => {
    navigation.toggleDrawer()
  }

	return (
    <SafeAreaView style={styles.wrap}>
      <TouchableWithoutFeedback onPress={handlePress}>
      <Icon 
        name={'menu-sharp'}
        style={styles.icon}
      />
      </TouchableWithoutFeedback>
    </SafeAreaView>
	)
}

export default DrawerHeader