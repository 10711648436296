import React, { useMemo } from 'react'
import { View, StyleSheet, FlatList, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { useTheme, useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { sortBy } from 'lodash'
import { Text, HTMLRenderer, Button } from 'components'
import { Theme } from 'styles'

const Techniques = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()
  const navigation = useNavigation()

  const techniques = useMemo(() => {
    return sortBy(contentStore.techniques, 'title')
  }, [contentStore.techniques])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    flatList: {
      paddingBottom: bottom,
    },
    row: {
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      borderBottomWidth: 1,
      borderBottomColor: colors.medium,
    }
  })

  const handlePress = (item) => {
    navigation.push('Technique', { id: item.id, title: item.title })
  }

  const renderItem = ({item}) => {
    return (
      <Pressable 
        onPress={() => handlePress(item)}
        style={styles.row}
      >
        <Text>{item.title}</Text>
      </Pressable>
    )
  }

  return (
    <View style={styles.wrap}>
      <FlatList 
        data={techniques}
        contentContainerStyle={styles.flatList}
        keyExtractor={(item) => item.slug}
        renderItem={renderItem}
      />
    </View>
  )
})

export default Techniques