import * as Linking from 'expo-linking'

const routes = {
  prefixes: [ Linking.createURL('/'), 'https://www.suture.app'],
  config: {
    screens: {
      Home: '/',
      AboutStack: {
        screens: {
          About: 'about/:page?',
        },
      },
      BasicsStack: {
        screens: {
          Basics: 'basics/:page?',
        },
      },
      TechniquesStack: {
        screens: {
          Techniques: 'techniques',
          Technique: 'technique/:id?/:title?'
        },
      },
      FeedbackStack: {
        screens: {
          Feedback: 'feedback',
        },
      },
      SettingsStack: {
        screens: {
          Settings: 'settings',
        },
      },
    },
  },
}

export {
  routes
}