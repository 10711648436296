import React, { useEffect } from 'react'
import { StyleSheet, Pressable, View, SafeAreaView } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { ScrollView, Text, Button, Message } from 'components'
import { Recommendations, Notice } from 'components/Recommendations'
import { Theme } from 'styles'

const Repair = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, pickerStore, modalStore, scrollStore } = rootStore
  const repair = contentStore.filteredRepairs
	const { colors } = useTheme()

  const handleReset = () => {
    pickerStore.reset()
    appStore.reset()
    modalStore.close()
    scrollStore.scrollToTop()
  }

  useEffect(function showModalWhenRepairAvailable () {
    modalStore.setIndex(repair ? 1 : 0)
  }, [repair])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
    },
    emptyWrap: {
      paddingHorizontal: Theme.spacing.layout,
    },
    recommendations: {
      flex: 1,
    },
    scrollView: {
      paddingHorizontal: Theme.spacing.layout,
    },
    message: {
      marginHorizontal: Theme.spacing.layout,
    },
    footer: {
      marginLeft: Theme.spacing.layout,
      marginBottom: Theme.spacing.layout,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    button: {
      flex: 1,
      marginRight: Theme.spacing.layout,
    },
  })

  if (repair) {
    return (
      <SafeAreaView style={styles.wrap}>
        <View style={styles.recommendations}>
          <ScrollView contentContainerStyle={styles.scrollView}>
            <Notice />
            <Recommendations />
          </ScrollView>
        </View>
        <View style={styles.footer}>
          <Button
            style={styles.button}
            text={'Start Over'}
            icon={'trash-sharp'}
            onPress={handleReset}
          />
        </View>
      </SafeAreaView>
    )
  } else {
    return (
      <SafeAreaView style={styles.wrap}>
        <View style={styles.message}>
          <Notice />
        </View>
        <View style={styles.footer}>
          <Button
            style={styles.button}
            text={'Close'}
            icon={'close-sharp'}
            onPress={() => modalStore.close()}
          />
        </View>
      </SafeAreaView>
    )
  }

})

export default Repair