import React, { useState } from 'react'
import { StyleSheet, Pressable, View, Image } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text, Icon } from 'components'
import { withHapticImpact } from 'utils'
import { startCase } from 'lodash'
import pluralize from 'pluralize'
import { decode } from 'html-entities'
import { Theme } from 'styles'

const Buttons = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()

  const { taxonomy } = props

  const styles = StyleSheet.create({
    button: {
      flexDirection: 'row',
      padding: Theme.spacing.layout,
      backgroundColor: colors.light,
    },
    buttonActive: {
      backgroundColor: colors.text,
    },

    text: {
      flex: 1,
    },
    textActive: {
      color: colors.background,
    },
    icon: {},
    iconActive: {},

    description: {
      backgroundColor: colors.medium,
      padding: Theme.spacing.thin,
    },
  })

  const handleTaxonomy = (item) => {
    appStore.toggleTaxonomy(taxonomy, item.id)
    withHapticImpact('Light')
  }

  return contentStore[taxonomy].map((item, index) => {
    let isSelected = item.id === appStore.getTaxonomy(taxonomy)

    return (
      <View style={styles.wrap} key={`prophylaxis-selector-button-${item.id}`}>
        <Pressable
          style={[
            styles.button, 
            isSelected && styles.buttonActive,
          ]}
          onPress={() => handleTaxonomy(item)}
        >
          <Text
            style={[
              styles.text, 
              isSelected && styles.textActive,
            ]}
          >
            {decode(item.title)}
          </Text>
          <Icon
            name={isSelected ? 'checkmark-circle-sharp' : 'ellipse-outline'}
            size={'h2'}
            color={isSelected ? 'background' : 'text'}
          />
        </Pressable>
        {isSelected && item.description ?
          <View style={styles.description}>
            <Text size={'sm'}>{item.description}</Text>
          </View>
          : null
        }
      </View>
    )
  })
})

export default Buttons