import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { Theme, themedRecommendations } from 'styles'

const Regimen = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const { prophylaxis } = props

  if (prophylaxis) {
    return (
      <View style={styles.content}>
        <Text size={'h3'} weight={'bold'}>{prophylaxis.title} Prophylaxis</Text>
        {prophylaxis.regimens?.map((regimen, index) => {
          return (
            <View
              style={styles.regimen}
              key={`${prophylaxis.title}-regimen-${index}`}
            >
              <View style={styles.regimenTitleWrap}>
                <Text size={'p'} weight={'medium'} style={styles.regimenTitle}>{regimen.title.toUpperCase()}</Text>
              </View>
              {regimen.drug.map((drug, index) => {
                return (
                  <View key={`${prophylaxis.title}-regimen-drug-${index}`} style={styles.regimenWrap}>
                    <Text style={styles.regimenBullet}>•</Text>
                    <Text style={styles.regimenText}>{drug.name} {drug.route} {drug.dose.amount}{drug.dose.units} {drug.frequency} {drug.duration && `for ${drug.duration} days`}</Text>
                  </View>
                )
              })}
            </View>
          )
        })}
      </View>
    )
  } else return false

})

export default Regimen