import React, { useState, useMemo } from 'react'
import { StyleSheet, ScrollView, View, RefreshControl, useWindowDimensions } from 'react-native'
import { useTheme, useRoute, useLinkTo } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { TabView as RNTabView, TabBar, SceneMap } from 'react-native-tab-view'
import { sortBy } from 'lodash'
import { HTMLRenderer } from 'components'
import { Theme } from 'styles'


const TabView = observer((props) => {

	const { contentStore } = useStores()
  const { width, height } = useWindowDimensions()
  const { bottom } = useSafeAreaInsets()
	const { colors } = useTheme()
  const linkTo = useLinkTo()
  const { params } = useRoute()

  const { pages, parent } = props

	const buildRoutes = useMemo(() => {
		let routes = []
		pages.map((page, index) => {
			routes.push({
				key: page.slug,
				title: page.title,
        sort_order: page.sort_order,
			})
		})
		return sortBy(routes, 'sort_order')
	}, [pages])

  const initialRoute = useMemo(() => {
    if (params?.page) {
      return buildRoutes.findIndex(route => {
        return route.key === params.page
      })
    } else return 0
  }, [params])

	const renderScene = ({ route }) => {
		let routePage = pages.find((page) => {
			return page.slug === route.key
		})

		return (
			<ScrollView 
				contentContainerStyle={styles.scrollView}
			>
				<HTMLRenderer html={routePage.content} />
			</ScrollView>
		)
	}

  const handleTabPress = ({ route }) => {
    linkTo(`/${parent}/${route.key}`)
  }

	const renderTabBar = (props) => {
		return (
			<TabBar
				{...props}
				activeColor={colors.text}
				inactiveColor={colors.textLight}
				scrollEnabled={true}
				getLabelText={({ route }) => route.title}
        onTabPress={handleTabPress}
				tabStyle={{
					width: 'auto',
				}}
				indicatorStyle={{
					height: 3,
					backgroundColor: colors.text,
				}}
				labelStyle={{
					fontSize: Theme.font.size.p,
					fontFamily: Theme.font.family.bold,
				}}
				style={{
					backgroundColor: 'transparent',
				}}
			/>
		)
	}

  const styles = StyleSheet.create({
  	view: {
  		flex: 1,
  	},
  	scrollView: {
      maxWidth: Theme.layout.maxWidth,
  		padding: Theme.spacing.layout,
  		paddingTop: Theme.spacing.thin,
      paddingBottom: Theme.spacing.layout + bottom,
  	},
  })

  const [ index, setIndex ] = useState(initialRoute)
  const [ routes, setRoutes ] = useState(buildRoutes)

	return (
		<View style={styles.view}>
			<RNTabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				renderTabBar={renderTabBar}
				onIndexChange={setIndex}
				initialLayout={{ width: width }}
			/>
		</View>
	)
})


export default TabView