import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useColorScheme, SafeAreaView, ActivityIndicator, Text } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer'
import * as Linking from 'expo-linking'
import { StatusBar } from 'expo-status-bar'
import AppLoading from 'expo-app-loading'
import { ContentLoading, IntroStack, Home, AboutStack, BasicsStack, TechniquesStack, FeedbackStack, SettingsStack } from 'screens'
import { Icon } from 'components'
import { DrawerHeader } from 'components/Navigation'
import { removeLogBox } from 'debug/settings'
import { Theme } from 'styles'
import { useFonts, initializeFirebase, logEvent, initializeSentry, getTheme, routes } from 'utils'

const App = observer(() => {

  const { rootStore } = useStores()
  const { appStore, contentStore, pickerStore, modalStore } = rootStore
	const scheme = useColorScheme()
  const fontsLoaded = useFonts()

  const navigationRef = useNavigationContainerRef()
  const routeNameRef = useRef()

  useEffect(() => {
    contentStore.loadContent()
    initializeFirebase()
    initializeSentry()
    removeLogBox()
  }, [])

  const handleDebug = () => {
    contentStore.loadDebug()
    appStore.loadDebug()
    pickerStore.loadDebug()
    modalStore.loadDebug()
  }

  const Drawer = createDrawerNavigator()
  const theme = useMemo(() => getTheme(scheme), [scheme])

  if (!fontsLoaded) {
    return (
      <AppLoading />
    )
  }
  else if (contentStore.loading) {
    return (
      <ContentLoading />
    )
  }
  else {
    return (
      <GestureHandlerRootView style={{flex: 1}}>
        <SafeAreaProvider>
          <NavigationContainer
            ref={navigationRef}
            linking={routes}
            documentTitle={{
              formatter: (options, route) => {
                return 'Suture.app'
              }
            }}
            onReady={() => {
              routeNameRef.current = navigationRef.getCurrentRoute().name
            }}
            onStateChange={async () => {
              const previousRouteName = routeNameRef.current
              const currentRouteName = navigationRef.getCurrentRoute().name

              if (previousRouteName !== currentRouteName) {
                await logEvent('screen_view', { screen_name: currentRouteName })
              }

              routeNameRef.current = currentRouteName
            }}
            theme={theme}
          >
            <StatusBar 
              animated={true} 
              backgroundColor={theme.colors.background}
            />
            <BottomSheetModalProvider>
        			{appStore.isHydrated && appStore.firstLaunch ? 
              <IntroStack />
        			:
              <Drawer.Navigator
                screenOptions={{
                  drawerPosition: 'right',
                  drawerActiveTintColor: theme.colors.text,
                  drawerActiveBackgroundColor: theme.colors.light,
                  drawerItemStyle: {borderRadius: 0,},
                  drawerLabelStyle: {fontFamily: Theme.font.family.medium,},
                  header: (props) => <DrawerHeader {...props} />,
                }}
              >
                <Drawer.Screen name={'Home'} component={Home} />
                <Drawer.Screen name={'AboutStack'} component={AboutStack} options={{ title: 'About' }} />
                <Drawer.Screen name={'BasicsStack'} component={BasicsStack} options={{ title: 'Basics' }} />
                <Drawer.Screen name={'TechniquesStack'} component={TechniquesStack} options={{ title: 'Techniques' }} />
                <Drawer.Screen name={'FeedbackStack'} component={FeedbackStack} options={{ title: 'Feedback' }} />
                <Drawer.Screen name={'SettingsStack'} component={SettingsStack} options={{ title: 'Settings' }} />
              </Drawer.Navigator>
            }
            </BottomSheetModalProvider>
          </NavigationContainer>
        </SafeAreaProvider>
      </GestureHandlerRootView>
    )
  }
})

export default App