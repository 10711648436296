import React from 'react'
import { View, Image, useColorScheme } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useNavigation } from '@react-navigation/native'
import pluralize from 'pluralize'
import { Text } from 'components'
import { getIcon } from 'components/Recommendations'
import { Theme, themedRecommendations } from 'styles'

const Technique = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const scheme = useColorScheme()
  const styles = themedRecommendations(colors)

  const navigation = useNavigation()

  const { techniques } = props.recommendation

  let title = pluralize('Technique', techniques.length)

  const renderTechniques = () => {
    return techniques.map((technique) => {
      let object = contentStore.getTechniqueById(technique.id)
      if (techniques.length > 1) {
        return (
          <View style={styles.technique} key={`technique-${object.slug}`}>
            <Image source={getIcon(technique.depth, scheme)} style={styles.depthIcon} />
            <Text size={'h3'}>{object.title}</Text>
          </View>
        )
      } else {
        return (
          <View style={styles.technique} key={`technique-${object.slug}`}>
            <Text size={'h3'}>{object.title}</Text>
          </View>
        )
      }
    })
  }

  return (
    <View style={styles.content}>
      <Text size={'h3'} weight={'bold'}>{title}</Text>
      {renderTechniques()}
    </View>
  )

})

export default Technique