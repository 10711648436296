import React, { useState } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { Button } from 'components/TaxonomySelector'
import { startCase } from 'lodash'
import { Theme } from 'styles'

const Buttons = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  

  const laceration = contentStore.currentLacerationByLocation
  const { taxonomy } = props

  return contentStore.getFilteredTaxonomyObjectsByIds(taxonomy, laceration[taxonomy]).map((item) => {
    return (
      <Button 
        key={`taxonomy-selector-button-${item.id}`}
        item={item}
        taxonomy={taxonomy}
      />
    )
  })

})

export default Buttons