import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { Image } from 'components/TaxonomySelector'
import { Theme, themedTaxonomyButton } from 'styles'

const NullButton = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedTaxonomyButton(colors)
  const { taxonomy } = props

  const [ isSelected, setSelected ] = useState(false)

  const handleNull = () => {
    setSelected(!isSelected)
    appStore.setTaxonomy(taxonomy, null)
  }

  useEffect(function setUnselectedIfTaxonomySet() {
    if (appStore.getTaxonomy(taxonomy)) {
      setSelected(false)
    }
  }, [appStore.getTaxonomy(taxonomy)])

  useEffect(function setUnselectedIfParentTaxonomyUnset() {
    if (!appStore.hasTaxonomyAndParentTaxonomySet(taxonomy)) {
      setSelected(false)
    }
  }, [appStore.hasTaxonomyAndParentTaxonomySet(taxonomy)])

  return (
    <Pressable
      onPress={handleNull}
      style={[
        styles.button,
        isSelected && styles.buttonActive
     ]}
    >
      <Image isSelected={isSelected} slug={'none'} taxonomy={taxonomy} />
      <Text style={[
        styles.buttonText,
        isSelected && styles.buttonTextActive
      ]}>NONE</Text>
    </Pressable>
  )

})

export default NullButton