import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { StackHeader } from 'components/Navigation'
import { Techniques, Browser } from 'screens'
import Technique from 'screens/Techniques/Technique'
import { Theme } from 'styles'

const AboutStack = () => {

  const Stack = createNativeStackNavigator()

  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => {
          return (
            <StackHeader 
              size={'h1'}
              containerStyle={{paddingTop: Theme.layout.drawerHeader.height}}
              {...props}
            />
          )
        },
      }}
    >
      <Stack.Screen name={'Techniques'} component={Techniques} />
      <Stack.Screen name={'Technique'} component={Technique} options={({ route }) => ({ title: route.params.title })} />
      <Stack.Screen name={'Browser'} component={Browser} />
    </Stack.Navigator>
  )
}

export default AboutStack