const contentStoreDebug = {
  pages: require('../data/pages.json'),
  lacerations: require('../data/lacerations.json'),
  locations: require('../data/locations.json'),
  repairs: require('../data/repairs.json'),
  techniques: require('../data/techniques.json'),
  taxonomies: require('../data/taxonomies.json'),
  depths: require('../data/depths.json'),
  shapes: require('../data/shapes.json'),
  complications: require('../data/complications.json'),
  precautions: require('../data/precautions.json'),
  prophylaxes: require('../data/prophylaxes.json'),
  contaminations: require('../data/contaminations.json'),
  immunizations: require('../data/immunizations.json'),
  notes: require('../data/notes.json'),
}

const appStoreDebug = {
  locations: [2, 4],
  taxonomy: [
    { title: 'depth', value: 52 },
    { title: 'shape', value: 18 },
    { title: 'complication', value: null },
    { title: 'contamination', value: null },
    { title: 'immunization', value: null },
  ],
  firstLaunch: false,
}

const pickerStoreDebug = {
  parent: {
    open: false,
    value: 'head',
  },
  child: {
    open: false,
    value: 'scalp',
  },
}

const modalStoreDebug = {
  index: 2,
}

export {
  contentStoreDebug,
  appStoreDebug,
  pickerStoreDebug,
  modalStoreDebug,
}