import React from 'react'
import { SafeAreaView, View, StyleSheet, Pressable } from 'react-native'
import { useTheme, useNavigation } from '@react-navigation/native'
import { getHeaderTitle } from '@react-navigation/elements'
import { observer } from 'mobx-react-lite'
import { Text, Icon } from 'components'
import { Theme } from 'styles'

const StackHeader = observer((props) => {

	const navigation = useNavigation()
	const { colors } = useTheme()
  const { route, options, back } = props
  const {
    size = 'h2',
    containerStyle = null,
    titleContainerStyle = null,
    titleTextStyle = null,
    backIconContainerStyle = null,
    backIconStyle = null,
    safe = false,
  } = props

  const title = getHeaderTitle(options, route.name)

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      backgroundColor: colors.background,
      ...containerStyle,
    },
    backIconContainer: {
      justifyContent: 'center',
      paddingLeft: Theme.spacing.thin,
      marginRight: -Theme.spacing.layout,
      width: 50,
      zIndex: 1,
      ...backIconContainerStyle,
    },
    backIcon: {
      ...backIconStyle,
    },

    titleContainer: {
      flex: 1,
      paddingHorizontal: Theme.spacing.layout,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      ...titleContainerStyle,
    },
    title: {
      ...titleTextStyle,
    },
  })

  const renderBackButton = () => {
    if (back) {
      return (
        <Pressable style={styles.backIconContainer} onPress={() => navigation.pop()}>
          <Icon
            name={'chevron-back-sharp'}
            style={styles.backIcon}
            size={size}
          />
        </Pressable>
      )
    }
  }

  const StackHeaderComponent = () => {
    return (
      <View style={styles.container}>
        {renderBackButton()}
        <View style={styles.titleContainer}>
          <Text 
            size={size}
            weight={'bold'}
            style={styles.title}
            numberOfLines={1}
            ellipsizeMode={'tail'}
          >
            {title}
          </Text>
        </View>
      </View>
    )
  }

  if (props.safe) {
    return (
      <SafeAreaView>
        <StackHeaderComponent />
      </SafeAreaView>
    )
  } else return <StackHeaderComponent />

})

export default StackHeader