import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Button, Text, Icon } from 'components'
import { Theme } from 'styles'

const SendButton = ({
  status = null, // ['loading', 'error', 'success']
	onSubmit = () => { return null },
	onReset = () => { return null },
}) => {

  const styles = StyleSheet.create({
    wrap: {
      flexDirection: 'row',
    },
    message: {
      flex: 3,
    },
    messageContent: {
      flex: 1,
      justifyContent: 'center',
    },
    messageText: {
      paddingRight: Theme.spacing.border,
    },
    messageIcon: {
      paddingRight: Theme.spacing.border,
    },

    button: {
      flex: 2,
    },
  })

  switch (status) {
    case 'loading':
      return (
        <Button
          text={'Sending...'}
          disabled={true}
        />
      )
    break

    case 'error':
      return (
        <View style={styles.wrap}>
          <View style={styles.message}>
            <View style={styles.messageContent}>
              <Icon name={'warning-sharp'} color={'error'} style={styles.messageIcon} />
              <Text size={'sm'} style={styles.messageText}>An error occurred please try again</Text>
            </View>
          </View>
          <View style={styles.button}>
            <Button
              text={'Retry'}
              icon={'refresh-sharp'}
              onPress={onSubmit}
            />
          </View>
        </View>
      )
    break

    case 'success':
      return (
        <View style={styles.wrap}>
          <View style={styles.message}>
            <View style={styles.messageContent}>
              <Icon name={'checkmark-sharp'} color={'accent'} style={styles.messageIcon} />
              <Text size={'sm'} style={styles.messageText}>Email sent successfully!</Text>
            </View>
          </View>
          <View style={styles.button}>
            <Button
              text={'Reset'}
              icon={'refresh-sharp'}
              onPress={onReset}
            />
          </View>
        </View>
      )
    break

    default:
      return (
        <Button
          text={'Send'}
          onPress={onSubmit}
        />
      )
  }

}

export default SendButton