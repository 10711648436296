import React, { useState } from 'react'
import { StyleSheet, View, Pressable, Image } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text } from 'components'
import { startCase } from 'lodash'
import { Theme } from 'styles'

const Description = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()

  const taxonomy = props.taxonomy
  const item = contentStore.getTaxonomyObjectById(taxonomy, appStore.getTaxonomy(taxonomy))

  const styles = StyleSheet.create({
    description: {
      backgroundColor: colors.medium,
      padding: Theme.spacing.thin,
    },
  })

  if (item && item.description) {
    return (
      <View style={styles.description}>
        <Text size={'sm'} style={styles.descriptionText}>{item.description}</Text>
      </View>
    )
  } else return false

})

export default Description