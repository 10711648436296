import React, { useMemo, useState, useRef } from 'react'
import { useColorScheme, useWindowDimensions, SafeAreaView, View, StyleSheet, Image, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@react-navigation/native'
import Carousel from 'react-native-reanimated-carousel'
import { useStores } from 'stores'
import { Text, Button } from 'components'
import { Theme } from 'styles'

const Intro = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const { width, height } = useWindowDimensions()
  const scheme = useColorScheme()

  const carouselRef = useRef(null)
  const [ index, setIndex ] = useState(0)

  const imagePath = useMemo(() => {
    let light = require('assets/icon.png')
    let dark = require('assets/icon-dark.png')
    return scheme === 'light' ? light : dark
  }, [scheme])

  const imageSize = useMemo(() => {
    return width/2
  }, [width])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      alignItems: 'center',
    },
    logoWrap: {
      flex: 1,
      justifyContent: 'flex-end',
    },
    logo: {
      width: imageSize,
      maxWidth: 300,
      height: imageSize,
      maxHeight: 300,
    },
    carouselWrap: {
      alignItems: 'center',
      flex: 1,
    },
    content: {
      marginHorizontal: Theme.spacing.layout,
      backgroundColor: colors.light,
      padding: Theme.spacing.layout,
    },
    pagerWrap: {
      flexDirection: 'row',
      marginVertical: Theme.spacing.layout,
    },
    dot: {
      width: Theme.spacing.border,
      height: Theme.spacing.border,
      marginRight: Theme.spacing.border,
      backgroundColor: colors.medium,
      borderRadius: Theme.spacing.border,
    },
    dotActive: {
      backgroundColor: colors.text,
    },
    footerWrap: {
      marginLeft: Theme.spacing.layout,
      marginBottom: Theme.spacing.layout,
      flexDirection: 'row',
    },
    buttonWrap: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    buttonWrapSingle: {
      justifyContent: 'flex-end',
    },
    button: {
      flex: 1,
      marginRight: Theme.spacing.layout,
    },
    buttonSingle: {},
  })

  const data = [
    { title: 'Getting Started', content: 'Start by selecting the location. We’ll start giving preliminary recommendations as soon as you’ve made enough choices.' },
    { title: 'Recommendations', content: 'Pull up the sheet at the bottom of the screen to view developing recommendations.' },
    { title: 'Techniques', content: 'Get instructions for simple and advanced techniques based on your laceration properties.' },
  ]

  const renderItem = ({ item }) => {
    return (
      <View style={styles.content}>
        <Text size={'h2'} weight={'bold'}>{item.title}</Text>
        <Text>{item.content}</Text>
      </View>
    )
  }

  const renderPager = () => {
    return data.map((item, currentIndex) => {
      return (
        <Pressable
          key={`pager-dot-${currentIndex}`}
          style={[
            styles.dot,
            index === currentIndex && styles.dotActive,
          ]}
        />
      )
    })
  }

  const renderFooter = () => {
    if (index !== data.length -1) {
      return (
        <View style={styles.buttonWrap}>
          <Button text={'Skip'} onPress={handleSkip} style={styles.button} />
          <Button text={'Next'} icon={'chevron-forward-sharp'} onPress={handleNext} style={styles.button} />
        </View>
      )
    } else {
      return (
        <View style={[styles.buttonWrap, styles.buttonWrapSingle]}>
          <Button text={'Start'} icon={'checkmark-sharp'} onPress={handleSkip} style={styles.button} />
        </View>
      )
    }
  }

  const handleSkip = () => {
    appStore.introComplete()
  }
  const handleNext = () => {
    carouselRef.current.next()
  }

  return (
    <SafeAreaView style={styles.wrap}>
      <View style={styles.logoWrap}>
        <Image source={imagePath} style={styles.logo} />
      </View>
      <View style={styles.carouselWrap}>
        <Carousel
          ref={carouselRef}
          width={width}
          data={data}
          loop={false}
          renderItem={renderItem}
          onSnapToItem={setIndex}
        />
      </View>
      <View style={styles.pagerWrap}>
        {renderPager()}
      </View>
      <View style={styles.footerWrap}>
        {renderFooter()}
      </View>
    </SafeAreaView>
  )
})

export default Intro