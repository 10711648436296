import APIpeline from 'apipeline'
import AsyncStorage from '@react-native-async-storage/async-storage'
import fetch from 'cross-fetch'
import { sutureapp } from 'config/environment'

const cacheMiddleware = () => {
  return {
    headers: {
      'Cache-Control': 'no-cache',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
}

const DebugAPIOptions = {
  // debugAPI: true,
  disableCache: true,
}

const APIOptions = {
	fetchMethod: fetch,
	domains: { default: sutureapp[process.env.NODE_ENV].domain },
	prefixes: { default: sutureapp[process.env.NODE_ENV].api },
  cacheExpiration: 1000 * 60 * 60 * 24 * 7,
  capLimit: 1000,
  middlewares: [ cacheMiddleware ],
  ...(__DEV__) && DebugAPIOptions,
}

const APIServices = {
  pages: {
    path: 'pages',
		responseMiddleware: data => transformPosts(data),
  },
  lacerations: {
    path: 'lacerations',
		responseMiddleware: data => transformLacerations(data),
  },
  locations: {
    path: 'locations',
		responseMiddleware: data => transformLocations(data),
  },
  repairs: {
    path: 'repairs',
		responseMiddleware: data => transformRepairs(data),
  },
  techniques: {
    path: 'techniques',
		responseMiddleware: data => transformPosts(data),
  },
  taxonomies: {
    path: 'taxonomies',
		responseMiddleware: data => transformTaxonomies(data),
  },
  depths: {
    path: 'depths',
		responseMiddleware: data => transformTaxonomy(data),
  },
  shapes: {
    path: 'shapes',
		responseMiddleware: data => transformTaxonomy(data),
  },
  complications: {
    path: 'complications',
		responseMiddleware: data => transformTaxonomy(data),
  },
  precautions: {
    path: 'precautions',
		responseMiddleware: data => transformPosts(data),
  },
  prophylaxes: {
    path: 'prophylaxes',
		responseMiddleware: data => transformProphylaxes(data),
  },
  contaminations: {
    path: 'contaminations',
		responseMiddleware: data => transformTaxonomy(data),
  },
  immunizations: {
    path: 'immunizations',
		responseMiddleware: data => transformTaxonomy(data),
  },
  notes: {
    path: 'notes',
		responseMiddleware: data => transformPosts(data),
  }
}

const transformLacerations = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      locations: item.locations,
      slug: item.slug,
      title: item.title.rendered,
      isParent: item.parent ? false : true,
      parentId: item.parent,
      shell: item.acf.parent_shell,
      shapes: item.acf.shapes,
      depths: item.acf.depths,
      complications: item.acf.complications,
      contaminations: item.acf.contaminations,
      sort_order: item.menu_order,
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}

const transformRepairs = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      locations: item.locations,
      slug: item.slug,
      title: item.title.rendered,
      shape: item.acf.shape,
      depth: item.acf.depth,
      complication: item.acf.complication,
      recommendations: item.acf.recommendations,
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}

const transformPosts = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      slug: item.slug,
      title: item.title.rendered,
      content: item.content.rendered,
			parent: item.parent,
      sort_order: item.menu_order,
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}

const transformTaxonomy = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      sort_order: item.acf.sort_order,
      slug: item.slug,
      title: item.name,
      description: item.description,
      image: {
        light: item.acf.image?.light,
        dark: item.acf.image?.dark,
      }
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}

const transformTaxonomies = (data) => {
	let revisedData = []

  for (const [key, item] of Object.entries(data)) {
		let revisedItem = {
      title: item.description || item.name,
      slug: item.slug,
		}
    revisedData.push(revisedItem)
  }

	return revisedData
}

const transformProphylaxes = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      slug: item.slug,
      title: item.title.rendered,
      contaminations: item.contaminations,
      immunizations: item.immunizations,
      type: item.acf.type,
      regimens: item.acf.regimens,
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}

const transformLocations = (data) => {
	let revisedData = []

  data.map((item) => {
		let revisedItem = {
			id: item.id,
      slug: item.slug,
      title: item.name,
      isParent: item.parent ? false : true,
      parent: item.parent,
		}
    revisedData.push(revisedItem)
  })

	return revisedData
}


const apiConnect = new APIpeline(APIOptions, APIServices, AsyncStorage)

const fetchOptions = (
  options={
    acfFormat: false,
    getMaximum: false
  }
) => {
  return {
    queryParameters: {
      ...(options.acfFormat) && { acf_format: 'standard' },
      ...(options.getMaximum) ? { per_page: 1000 } : { per_page: 100 },
    }
  }
}


export {
  apiConnect,
  fetchOptions
}