import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { Text, Icon } from 'components'
import { Regimen } from 'components/Recommendations'
import { Theme, themedRecommendations } from 'styles'

const Prophylaxis = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedRecommendations(colors)

  const { recommendation } = props

  // Find prophylaxis unique to recommendation
  const prophylaxisForRecommendation = () => {
    if (recommendation.prophylaxis.length) {
      return contentStore.getfilteredProphylaxis('repair').find(prophylaxis => {
        return prophylaxis.id === recommendation.prophylaxis[0]
      })
    }
  }

  // Find prophylaxis for contamination if selected
  const prophylaxisForContamination = () => {
    if (appStore.getTaxonomy('contaminations')) {
      return contentStore.getfilteredProphylaxis('contaminations').find(prophylaxis => {
        return prophylaxis.contaminations.includes(appStore.getTaxonomy('contaminations'))
      })
    }
  }

  // Find prophylaxis for immunization if selected
  const prophylaxisForImmunization = () => {
    if (appStore.getTaxonomy('immunizations')) {

      // If contamination is also selected
      if (appStore.getTaxonomy('contaminations')) {
        return contentStore.getfilteredProphylaxis('immunizations').find(prophylaxis => {
          // Check if the prophylaxis for immunization applies to specific contaminations
          if (prophylaxis.contaminations.length) {
            // If so, match both the contamination and immunization
            return prophylaxis.contaminations.includes(appStore.getTaxonomy('contaminations')) && prophylaxis.immunizations.includes(appStore.getTaxonomy('immunizations'))
          } else {
            // Otherwise just match the immunization
            return prophylaxis.immunizations.includes(appStore.getTaxonomy('immunizations'))
          }
        })
      }
      // If a contamination is not selected, ensure use of prophylaxes not assigned to specific contaminations
      else {
        return contentStore.getfilteredProphylaxis('immunizations')
          .filter(prophylaxis => !prophylaxis.contaminations.length)
          .find(prophylaxis => prophylaxis.immunizations.includes(appStore.getTaxonomy('immunizations')))
      }
    }
  }


  if (appStore.getTaxonomy('contaminations') || appStore.getTaxonomy('immunizations') || recommendation.prophylaxis) {
    return (
      <View style={styles.module}>

        <View style={styles.title}>
          <Icon size={'h3'} name={'medical-sharp'} /> 
          <Text size={'h3'} style={styles.titleText}>Prophylaxis Details</Text>
        </View>

        <Regimen prophylaxis={prophylaxisForRecommendation()} />
        <Regimen prophylaxis={prophylaxisForContamination()} />
        <Regimen prophylaxis={prophylaxisForImmunization()} />
        
      </View>
    )
  } else return false

})

export default Prophylaxis