import fetch from 'cross-fetch'
import { alert } from 'config/environment'

const send = async (endpoint, data) => {
  let url = `${alert[process.env.NODE_ENV].domain}${alert[process.env.NODE_ENV].api}/${endpoint}`

  const message = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  })

  if (!message.ok) {
    throw new Error('network-error')
  }

  return message
}

export {
  send,
}