import React, { forwardRef } from 'react'
import { Platform, ScrollView as RNScrollView } from 'react-native'
import { BottomSheetScrollView } from '@gorhom/bottom-sheet'

const ScrollView = forwardRef((props, ref) => {

  if (Platform.OS === 'web') {
    return <RNScrollView ref={ref} {...props} />
  } else {
    return (
      <BottomSheetScrollView ref={ref} {...props} />
    )
  }

})

export default ScrollView