import React, { useState, useEffect } from 'react'
import { StyleSheet, useWindowDimensions, SafeAreaView, View } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme, useNavigation } from '@react-navigation/native'
import { useStores } from 'stores'
import { Text, TextInput, ScrollView, Message, Button, SendButton, HTMLRenderer } from 'components'
import { Theme } from 'styles'
import { send } from 'utils'

const Precautions = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, modalStore } = rootStore
	const { colors } = useTheme()
  const navigation = useNavigation()
  const width = useWindowDimensions().width

  const [ email, setEmail ] = useState(appStore.defaultEmail)
  const [ status, setStatus ] = useState(null)

  const { id } = props.route.params
  const precaution = contentStore.getPrecautionById(id)

  useEffect(function popStack() {
    if (modalStore.isClosed) {
      navigation.popToTop()
    }
  }, [modalStore.index])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
    },

    header: {
      marginBottom: Theme.spacing.border,
    },

    preview: {
      flex: 1,
      margin: Theme.spacing.layout,
    },
    scrollView: {
      padding: Theme.spacing.layout,
      backgroundColor: colors.light,
    },

    message: {
      marginBottom: Theme.spacing.layout,
    },

    input: {
      marginBottom: Theme.spacing.layout,
      fontSize: Theme.font.size.h3,
      fontFamily: Theme.font.family.regular,
      padding: Theme.spacing.thin,
      backgroundColor: colors.light,
      color: colors.text,
    },

    sendWrap: {
      marginHorizontal: Theme.spacing.layout,
      marginBottom: Theme.spacing.layout,
    },
  })

  const handleSend = async () => {
    try {
      setStatus('loading')
      const message = await send('precaution', {
        email: email,
        id: String(id),
      })
      setStatus('success')
    }
    catch (error) {
      setStatus('error')
    }
  }

  const handleReset = () => {
    setStatus(null)
  }

  return (
    <SafeAreaView style={styles.wrap}>

      <View style={styles.preview}>
        <Text size={'h3'} weight={'bold'} style={styles.header}>Preview</Text>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <HTMLRenderer html={precaution.content} />
        </ScrollView>
      </View>

      <View style={styles.sendWrap}>
        <Text size={'h3'} weight={'bold'} style={styles.header}>Send by Email</Text>
        <Message 
          title={'Note'}
          message={'These instructions are not intended to be emailed directly to patients.'}
          icon={'warning-sharp'}
          containerStyle={styles.message}
        />
        <TextInput 
          style={styles.input}
          autoComplete={'email'}
          autoCorrect={false}
          autoFocus={false}
          autoCapitalize={'none'}
          placeholder={'Email address'}
          placeholderTextColor={colors.dark}
          defaultValue={appStore.defaultEmail}
          keyboardType={'email-address'}
          onChangeText={setEmail}
          returnKeyType={'send'}
          textContentType={'emailAddress'}
          onSubmitEditing={handleSend}
        />
        <SendButton
          onSubmit={handleSend}
          onReset={handleReset}
          status={status}
        />
      </View>
    </SafeAreaView>
  )
})

export default Precautions