import React, { useState, useEffect } from 'react'
import * as Font from 'expo-font'

const useFonts = () => {
	const [ fontsLoaded, setFontsLoaded ] = useState(false)

	useEffect(async() => {
		try {
			await Font.loadAsync({
				'Poppins_100Thin': require('assets/fonts/Poppins_100Thin.ttf'),
				'Poppins_300Light': require('assets/fonts/Poppins_300Light.ttf'),
				'Poppins_400Regular': require('assets/fonts/Poppins_400Regular.ttf'),
				'Poppins_400Regular': require('assets/fonts/Poppins_400Regular.ttf'),
				'Poppins_500Medium': require('assets/fonts/Poppins_500Medium.ttf'),
				'Poppins_600SemiBold': require('assets/fonts/Poppins_600SemiBold.ttf'),
				'Poppins_700Bold': require('assets/fonts/Poppins_700Bold.ttf'),
			})
		} catch ({ message }) {
			console.log(`Error loading font: ${message}`)
		} finally {
			setFontsLoaded(true)
		}
	})
	
  return fontsLoaded
}

export {
	useFonts,
}