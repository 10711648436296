import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { StackHeader } from 'components/Navigation'
import { Basics, Browser } from 'screens'
import { Theme } from 'styles'

const BasicsStack = () => {

  const Stack = createNativeStackNavigator()

  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => {
          return (
            <StackHeader 
              size={'h1'}
              containerStyle={{paddingTop: Theme.layout.drawerHeader.height}}
              {...props}
            />
          )
        },
      }}
    >
      <Stack.Screen name={'Basics'} component={Basics} />
      <Stack.Screen name={'Browser'} component={Browser} />
    </Stack.Navigator>
  )
}

export default BasicsStack