import { Theme } from 'styles'
import { getPlatform } from 'utils'

const getTheme = (
  scheme='light',
  theme='main',
  platform='native'
) => {
  return scheme === 'dark' ? Theme[theme][platform].color.dark : Theme[theme][platform].color.light
}

export {
  getTheme,
}