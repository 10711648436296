import React, { forwardRef } from 'react'
import { Platform, TextInput as RNTextInput } from 'react-native'
import { BottomSheetTextInput } from '@gorhom/bottom-sheet'

const TextInput = forwardRef((props, ref) => {

  if (Platform.OS === 'web') {
    return <RNTextInput ref={ref} {...props} />
  } else {
    return (
      <BottomSheetTextInput ref={ref} {...props} />
    )
  }

})

export default TextInput