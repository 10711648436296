import React, { useMemo, useState, useEffect } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@react-navigation/native'
import { useStores } from 'stores'
import DropDownPicker from 'react-native-dropdown-picker'
import { Text, Icon, TaxonomySelector, ProphylaxisSelector } from 'components'
import { withHapticSelection, withHapticImpact } from 'utils'
import { Theme, themedPicker } from 'styles'


const buildDropdownData = (data) => {
  let newItems = []
  data.map(item => {
    let newItem = {}
    newItem.label = item.title
    newItem.value = item.slug
    newItem.locations = item.locations
    newItems.push(newItem)
  })
  return newItems
}

const LacerationSelector = observer(() => {
  const { rootStore } = useStores()
  const { appStore, contentStore, pickerStore } = rootStore
	const { colors } = useTheme()
  const picker = themedPicker(colors)

  const buildParentItems = buildDropdownData(contentStore.parentLacerations)
  const [ parentItems, setParentItems] = useState(buildParentItems)
  const handleParentSelectItem = (item) => {
    pickerStore.setParentValue(item.value)
    appStore.setLocations(item.locations)
    appStore.resetDetails()
    withHapticImpact('Light')
  }
  const handleParentSetOpen = (state) => {
    pickerStore.setParentOpenState(state)
    state && pickerStore.setChildOpenState(false)
  }

  const buildChildItems = buildDropdownData(contentStore.selectedChildLacerations)
  const [ childItems, setChildItems] = useState([])
  const handleChildSelectItem = (item) => {
    pickerStore.setChildValue(item.value)
    appStore.setLocations(item.locations)
    appStore.resetDetails()
    withHapticImpact('Light')
  }
  const handleChildSetOpen = (state) => {
    pickerStore.setChildOpenState(state)
    state && pickerStore.setParentOpenState(false)
  }

  useEffect(function updateChildItems() {
    setChildItems(buildChildItems)
  }, [pickerStore.parent.value])


  const renderDropdownIcon = (name, heirarchy) => {
    let color = pickerStore[heirarchy].value ? 'text' : 'error'
    return (
      <Icon name={name} color={color} style={picker.icon} />
    )
  }

  const sharedPickerProps = (heirarchy) => {
    return {
      open: pickerStore[heirarchy].open,
      value: pickerStore[heirarchy].value,
      disableBorderRadius: true,
      listMode: 'SCROLLVIEW',
      style: [
        picker.wrap,
        !pickerStore[heirarchy].value && picker.placeholder,
        pickerStore[heirarchy].open && picker.open,
      ],
      onPress: () => withHapticSelection(),
      itemStyle: picker.item,
      placeholderStyle: picker.placeholderText,
      textStyle: picker.text,
      dropDownContainerStyle: picker.dropdownContainer,
      listItemContainerStyle: picker.dropdownItemContainer,
      selectedItemLabelStyle: picker.dropdownItemLabelActive,
      ArrowDownIconComponent: () => renderDropdownIcon('caret-down', heirarchy),
      ArrowUpIconComponent: () => renderDropdownIcon('caret-up', heirarchy),
      TickIconComponent: () => <Icon name={'checkmark'} style={picker.icon} />,
    }
  }

  return (
    <View>
      <Text size={'h2'} weight={'bold'}>Location</Text>
      <DropDownPicker
        setOpen={handleParentSetOpen}
        items={parentItems}
        setItems={setParentItems}
        onSelectItem={handleParentSelectItem}
        placeholder={'Select a Location'}
        zIndex={2000}
        zIndexInverse={1000}
        disableBorderRadius={true}
        {...sharedPickerProps('parent')}
      />
      {pickerStore.parentValue &&
      <DropDownPicker
        setOpen={handleChildSetOpen}
        items={childItems}
        setItems={setChildItems}
        onSelectItem={handleChildSelectItem}
        placeholder={'Select a Sublocation'}
        zIndex={1000}
        zIndexInverse={2000}
        {...sharedPickerProps('child')}
      />
      }

      {appStore.locationsSet &&
        <>
          <TaxonomySelector taxonomy={'depths'} />
          <TaxonomySelector taxonomy={'shapes'} />
          <TaxonomySelector taxonomy={'complications'} />
          <ProphylaxisSelector taxonomy={'contaminations'} />
          <ProphylaxisSelector taxonomy={'immunizations'} />
        </>
      }
    </View>
  )

})

export default LacerationSelector