import React from 'react'
import { View } from 'react-native'
import Ionicon from '@expo/vector-icons/Ionicons'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Icon = ({
  name = null,
  color = 'text',
  size = 'p',
  style = null,
  containerStyle = {
    justifyContent: 'center',
  },
  onPress = null,
  family = 'Ionicon',
}) => {
	const { colors } = useTheme()


  switch (family) {
    case 'MaterialCommunityIcons':
      return (
        <View style={containerStyle}>
        	<MaterialCommunityIcons 
            name={name}
            size={Theme.font.size[size]}
            color={colors[color]}
            style={style}
            onPress={onPress}
          />
        </View>
      )
    break
    default: 
      return (
        <View style={containerStyle}>
        	<Ionicon 
            name={name}
            size={Theme.font.size[size]}
            color={colors[color]}
            style={style}
            onPress={onPress}
          />
        </View>
      )
    break
  }

}

export default Icon