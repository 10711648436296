import React, { useMemo } from 'react'
import { useColorScheme } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet'
import { getTheme } from 'utils'

const Backdrop = (props) => {
	const scheme = useColorScheme()
  const { colors } = useMemo(() => getTheme(scheme, 'repair'), [scheme])

  return (
    <BottomSheetBackdrop 
      {...props}
      style={{
        ...props.style,
        backgroundColor: colors.shadow,
      }}
      pressBehavior={0}
      appearsOnIndex={2}
      disappearsOnIndex={1}
    />
  )
}

export default Backdrop