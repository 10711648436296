import React, { useState, useRef } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useNavigation } from '@react-navigation/native'
import { startCase } from 'lodash'
import { FlatList, Text, Button } from 'components'
import { Recommendation } from 'components/Recommendations'
import { Theme } from 'styles'

const Recommendations = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, pickerStore, modalStore } = rootStore
	const { colors } = useTheme()

  const [ tabCords, setTabCords ] = useState([])
  const tabRef = useRef(null)

  const repair = contentStore.filteredRepairs

  const styles = StyleSheet.create({
    wrap: {},
    tabWrap: {
      flexDirection: 'row',
      flex: 1,
      marginBottom: Theme.spacing.border,
    },
    tabHeader: {
      marginRight: Theme.spacing.thin,
      alignSelf: 'center',
    },
    tab: {
      flex: 1,
      backgroundColor: colors.background,
    },
    tabActive: {
      backgroundColor: colors.light,
    },
    tabText: {
      color: colors.text,
    },
    tabActiveText: {},
  })

  const handleTab = (index) => {
    appStore.setRepairIndex(index)
    tabRef.current.scrollToIndex({
      index: index
    })
  }

  const renderTabs = () => {
    if (repair.recommendations.length > 1) {
      return (
        <View style={styles.tabWrap}>
          <Text style={styles.tabHeader}>Options:</Text>
          <FlatList
            ref={tabRef}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            data={repair.recommendations}
            keyExtractor={(item) => item.repair_type}
            renderItem={(data) => {
              let { item, index } = data
              let isActive = appStore.repairIndex === index
              return (
                <Button
                  style={[
                    styles.tab,
                    isActive && styles.tabActive,
                  ]}
                  textStyle={[
                    styles.tabText,
                    isActive && styles.tabActiveText,
                  ]}
                  text={startCase(item.repair_type)}
                  size={'small'}
                  onPress={() => handleTab(index)}
                />
              )
            }}
          />
        </View>
      )
    } else return false
  }

  const renderContent = () => {
    return (
      <Recommendation recommendation={repair.recommendations[appStore.repairIndex]} />
    )
  }

  return (
    <View style={styles.wrap}>
      <View style={styles.tabWrap}>
        {renderTabs()}
      </View>
      <View style={styles.content}>
        <Recommendation recommendation={repair.recommendations[appStore.repairIndex]} />
      </View>
    </View>
  )

})

export default Recommendations