import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View, SafeAreaView } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTheme, useNavigation } from '@react-navigation/native'
import { startCase } from 'lodash'
import { useStores } from 'stores'
import { FlatList, Text, HTMLRenderer, Button } from 'components'
import { Theme } from 'styles'

const Technique = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore, modalStore } = rootStore
	const { colors } = useTheme()
  const navigation = useNavigation()

  const techniques = props.route.params.techniques.slice().reverse()
  const techniqueScroll = useRef(null)
  const [ visibleIndex, setVisibleIndex ] = useState(0)
  const [ navigatorHeight, setNavigatorHeight ] = useState(0)

  useEffect(function popStack() {
    if (modalStore.isClosed) {
      navigation.popToTop()
    }
  }, [modalStore.index])

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
    },

    navigator: {
      position: 'absolute',
      right: 0, left: 0,
      backgroundColor: colors.background + Theme.opacity.opaque,
      flexDirection: 'row',
      paddingTop: Theme.spacing.thin,
      paddingBottom: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      zIndex: 1,
    },

    content: {
      flex: 1,
      paddingLeft: Theme.spacing.layout,
    },

    tab: {
      backgroundColor: colors.background,
    },
    tabActive: {
      backgroundColor: colors.light,
    },
    tabText: {
      flex: 0,
      color: colors.text,
    },
    tabActiveText: {},

    flatList: {
      paddingRight: Theme.spacing.layout,
    },
    technique: {
      paddingVertical: Theme.spacing.layout,
    },
    firstTechnique: {
      paddingTop: navigatorHeight,
    },
    title: {
      marginBottom: Theme.spacing.thin,
    },
    separator: {
      borderTopWidth: 2,
      borderColor: colors.text,
    }
  })

  const renderTechniques = () => {
    return techniques.slice().reverse().map((technique) => {
      let object = contentStore.getTechniqueById(technique.id)
      if (techniques.length > 1) {
        return (
          <View style={styles.technique} key={`technique-${object.slug}`}>
            <Text size={'sm'}>Superficial</Text>
            <Text size={'h1'} weight={'bold'}>{object.title}</Text>
            <HTMLRenderer html={object.content} />
          </View>
        )
      } else {
        return (
          <View style={styles.technique} key={`technique-${object.slug}`}>
            <Text size={'h1'} weight={'bold'}>{object.title}</Text>
            <HTMLRenderer html={object.content} />
          </View>
        )
      }
    })
  }

  const handleTab = (index) => {
    techniqueScroll.current.scrollToIndex({
      index: index,
      viewOffset: navigatorHeight,
    })
  }

  const handleNavigatorHeight = ({ nativeEvent }) => {
    setNavigatorHeight(nativeEvent.layout.height)
  }

  const renderNavigator = () => {
    if (techniques.length > 1) {
      return (
        <View 
          style={styles.navigator}
          onLayout={handleNavigatorHeight}
        >
          {techniques.map((technique, index) => {
            let isActive = visibleIndex === index
            return (
              <Button
                style={[
                  styles.tab,
                  isActive && styles.tabActive,
                ]}
                textStyle={[
                  styles.tabText,
                  isActive && styles.tabActiveText,
                ]}
                key={`technique-tab-${technique.depth}`}
                text={startCase(technique.depth)}
                size={'small'}
                onPress={() => handleTab(index)}
              />
            )
          })}
        </View>
      )
    } else return false
  }

  const renderTechnique = ({ item, index }) => {
    let object = contentStore.getTechniqueById(item.id)
    let multiple = techniques.length > 1
    return (
      <View
        style={[
          styles.technique,
          multiple && index === 0 && styles.firstTechnique,
        ]}
        key={`technique-${object.slug}`}
      >
        <Text size={'h1'} weight={'bold'} style={styles.title}>{object.title}</Text>
        <HTMLRenderer html={object.content} />
      </View>
    )
  }

  const renderSeparator = () => {
    return (
      <View style={styles.separator} />
    )
  }

  const handleViewableItemsChanged = useRef(({ viewableItems, changed }) => {
    setVisibleIndex(viewableItems[0]?.index)
  })

  const viewabilityConfig = useRef({ viewAreaCoveragePercentThreshold: 50 })



  return (
    <SafeAreaView style={styles.wrap}>
      {renderNavigator()}
      <View style={styles.content}>
        <FlatList
          ref={techniqueScroll}
          contentContainerStyle={styles.flatList}
          data={techniques}
          keyExtractor={(item) => `technique-${item.depth}`}
          renderItem={renderTechnique}
          ItemSeparatorComponent={renderSeparator}
          onViewableItemsChanged={handleViewableItemsChanged.current}
          viewabilityConfig={viewabilityConfig.current}
        />
      </View>
    </SafeAreaView>
  )
})

export default Technique