import { StyleSheet } from 'react-native'
import { Theme } from 'styles'

const themedRecommendations = (colors) => StyleSheet.create({
  notice: {
    marginBottom: Theme.spacing.layout,
  },
  module: {
    backgroundColor: colors.light,
    padding: Theme.spacing.layout,
    marginBottom: Theme.spacing.layout,
    borderRadius: Theme.spacing.border,
  },

  title: {
    paddingBottom: Theme.spacing.thin,
    borderBottomWidth: 1,
    borderBottomColor: colors.dark,
    flexDirection: 'row',
  },
  titleText: {
    marginLeft: Theme.spacing.thin,
  },

  footer: {
    marginTop: Theme.spacing.border,
  },

  suture: {
    flexDirection: 'row',
  },
  technique: {
    flexDirection: 'row',
  },
  depthIcon: {
    marginRight: Theme.spacing.border,
    width: 20, height: 20,
    alignSelf: 'center',
  },

  regimen: {
    marginTop: Theme.spacing.thin,
    paddingVertical: Theme.spacing.thin+Theme.spacing.border,
    paddingHorizontal: Theme.spacing.layout,
    borderRadius: Theme.spacing.border,
    backgroundColor: colors.background,
  },
  regimenTitleWrap: {},
  regimenTitle: {
    paddingBottom: Theme.spacing.border,
  },
  regimenWrap: {
    flexDirection: 'row',
  },
  regimenBullet: {
    marginRight: Theme.spacing.border,
  },
  regimenText: {
    flex: 1,
  },


  content: {
    paddingVertical: Theme.spacing.thin,
  },

})

export {
  themedRecommendations
}