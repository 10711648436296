import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Text, Icon } from 'components'
import { Theme } from 'styles'

const Button = ({
	text = 'Button',
	onPress = () => { return null },
	disabled = false,
	style = null,
	textStyle = null,
	icon = null,
	color= null,
	iconStyle = null,
	iconPosition = 'after',     // 'before', 'after'
  size = 'normal',            // 'normal', 'small'
}) => {

	const { colors } = useTheme()

	const handlePress = () => {
		onPress()
	}

	const renderIcon = () => {
		return (
			<Icon 
				name={icon}
				style={[
					common.icon,
					common[iconPosition],
          eval(size).icon,
					iconStyle
				]}
			/>
		)
	}

  const small = StyleSheet.create({
    button: {
      backgroundColor: colors.text,
      alignSelf: 'flex-start',
    },
  	text: {
  		fontSize: Theme.font.size.sm,
  		fontFamily: Theme.font.family.bold,
      color: colors.light,
  	},
    icon: {
      color: colors.light,
  		fontSize: Theme.font.size.sm,
    },
  })

  const normal = StyleSheet.create({
    button: {
  		borderWidth: 3,
      borderColor: color || colors.text,
    },
  	text: {},
  })

  const common = StyleSheet.create({
  	button: {
  		flexDirection: 'row',
  		paddingHorizontal: Theme.spacing.layout,
  		paddingVertical: Theme.spacing.thin,
  	},
  	disabled: {
  		opacity: 0.3,
  	},
  	text: {
      flex: 1,
  		fontSize: Theme.font.size.h3,
  		fontFamily: Theme.font.family.bold,
      color: color || colors.text,
  	},
  	icon: {
  		alignSelf: 'center',
  		fontSize: Theme.font.size.h3,
      color: color || colors.text,
  	},
  	before: {paddingRight: Theme.spacing.border},
  	after: {paddingLeft: Theme.spacing.border},
  })

	return (
		<TouchableOpacity 
			onPress={handlePress}
			style={[
				common.button,
				disabled && common.disabled,
        eval(size).button,
				style
			]}
			disabled={disabled}
		>
			{icon && iconPosition === 'before' && renderIcon()}
			{text &&
        <Text 
  				style={[
  					common.text,
            eval(size).text,
            textStyle
  				]}
        >
          {text}
        </Text>
      }
			{icon && iconPosition === 'after' && renderIcon()}
		</TouchableOpacity>
	)
}

export default Button