import React, { useState } from 'react'
import { StyleSheet, useWindowDimensions, SafeAreaView, View, Modal, Pressable, Text, Image, ActivityIndicator } from 'react-native'
import { useInternalRenderer } from 'react-native-render-html'
import { useTheme } from '@react-navigation/native'
import ImageView from 'react-native-image-modal'
import { Icon } from 'components'
import { Theme } from 'styles'

const ImageRenderer = (props) => {

	const { colors } = useTheme()
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(false)
  const [ modalVisible, setModalVisible ] = useState(false)

	const { rendererProps } = useInternalRenderer('img', props)
	const { source, alt, width, height } = rendererProps
	const classes = props.tnode.classes

  const screenWidth = useWindowDimensions().width
  const [ containerWidth, setContainerWidth ] = useState(screenWidth)
  const handleLayout = ({ nativeEvent }) => {
    let { width } = nativeEvent.layout
    setContainerWidth(width)
  }

	const styles = StyleSheet.create({
    wrap: {},
    status: {
			...StyleSheet.absoluteFillObject,
			alignItems: 'center',
			justifyContent: 'center',
      zIndex: 1,
    },
    loading: {},
    error: {},
    image: {
			aspectRatio: width / height,
      width: Math.min(containerWidth, width),
    },

    modal: {
      flex: 1,
      backgroundColor: colors.text + Theme.opacity.opaque,
    },
    modalDismiss: {
      flex: 1,
    },
    closeIcon: {
      alignSelf: 'flex-end',
      paddingHorizontal: Theme.spacing.thin,
      color: 'white',
    },
    modalContent: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }
	})

  const handleLoadEnd = () => {
    setLoading(false)
  }

  const handleError = () => {
    setError(true)
    setLoading(false)
  }
  
  const handlePress = () => {
    !classes.includes('no-lightbox') && setModalVisible(true)
  }

  const renderModalHeader = () => {
    return (
      <SafeAreaView>
        <Icon onPress={() => setModalVisible(false)} style={styles.closeIcon} name={'close-sharp'} color={'light'} size={'h1'} />
      </SafeAreaView>
    )
  }

  return (
    <View style={styles.wrap} onLayout={handleLayout}>
      {loading && <View style={[styles.status, styles.loading]}><ActivityIndicator color={colors.text} /></View>}
      {error && <View style={[styles.status, styles.loading]}><Icon name={'warning-sharp'} size={'h2'} /></View>}
      <ImageView
        presentationStyle={'fullScreen'}
        images={[source]}
        imageIndex={0}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false)
        }}
        backgroundColor={'#000000F2'}
        HeaderComponent={() => renderModalHeader()}
      />
      <Pressable onPress={handlePress}>
      	<Image
      		resizeMode={'contain'}
      		source={source}
      		style={styles.image}
          onLoad={handleLoadEnd}
          onError={handleError}
      	/>
      </Pressable>
    </View>
  )
}

export default ImageRenderer