import React, { useState, useMemo } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme } from '@react-navigation/native'
import { withHapticSelection, withHapticImpact } from 'utils'
import { Text } from 'components'
import { Image } from 'components/TaxonomySelector'
import { startCase, isEqual } from 'lodash'
import { Theme, themedTaxonomyButton } from 'styles'

const Button = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const styles = themedTaxonomyButton(colors)
  const { item, taxonomy } = props

  const handleTaxonomy = () => {
    appStore.toggleTaxonomy(taxonomy, item.id)
    withHapticImpact('Light')
  }

  let isSelected = item.id === appStore.getTaxonomy(taxonomy)

  const isDisabled = useMemo(() => {
    let index = appStore.getTaxonomyIndex(taxonomy)
    return contentStore.repairs.filter(repair => {
      if (index === 0) {
        return (
          isEqual(appStore.locations, repair.locations) &&
          isEqual(item.id, repair.depth)
        )
      }
      if (index === 1) {
        return (
          isEqual(appStore.locations, repair.locations) &&
          isEqual(appStore.getTaxonomy('depths'), repair.depth) &&
          isEqual(item.id, repair.shape)
        )
      }
      if (index === 2) {
        return (
          isEqual(appStore.locations, repair.locations) &&
          isEqual(appStore.getTaxonomy('depths'), repair.depth) &&
          isEqual(appStore.getTaxonomy('shapes'), repair.shape) &&
          isEqual(item.id, repair.complication)
        )
      }
    }).length ? false : true
  })

  return (
    <Pressable
      onPress={handleTaxonomy}
      style={[
        styles.button,
        isSelected && styles.buttonActive,
        isDisabled && styles.buttonDisabled,
     ]}
     disabled={isDisabled}
    >
      <Image isSelected={isSelected} slug={item.slug} taxonomy={taxonomy} />
      <Text style={[
        styles.buttonText,
        isSelected && styles.buttonTextActive
      ]}>{item.title.toUpperCase()}</Text>
    </Pressable>
  )

})

export default Button