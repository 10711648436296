const imageList = {
  depths: {
    superficial: {
      light: require('assets/images/superficial.png'),
      dark: require('assets/images/superficial-dark.png')
    },
    deep: {
      light: require('assets/images/deep.png'),
      dark: require('assets/images/deep-dark.png')
    },
  },
  shapes: {
    linear: {
      light: require('assets/images/linear.png'),
      dark: require('assets/images/linear-dark.png')
    },
    irregular: {
      light: require('assets/images/irregular.png'),
      dark: require('assets/images/irregular-dark.png')
    },
    stellate: {
      light: require('assets/images/stellate.png'),
      dark: require('assets/images/stellate-dark.png')
    },
  },
  complications: {
    'high-tension': {
      light: require('assets/images/high-tension.png'),
      dark: require('assets/images/high-tension-dark.png')
    },
    galea: {
      light: require('assets/images/galea.png'),
      dark: require('assets/images/galea-dark.png')
    },
    amputation: {
      light: require('assets/images/amputation.png'),
      dark: require('assets/images/amputation-dark.png')
    },
    cartilage: {
      light: require('assets/images/cartilage.png'),
      dark: require('assets/images/cartilage-dark.png')
    },
    'lid-margin': {
      light: require('assets/images/lid-margin.png'),
      dark: require('assets/images/lid-margin-dark.png')
    },
    cosmesis: {
      light: require('assets/images/cosmesis.png'),
      dark: require('assets/images/cosmesis-dark.png')
    },
    'vermilion-border': {
      light: require('assets/images/vermilion-border.png'),
      dark: require('assets/images/vermilion-border-dark.png')
    },
    through: {
      light: require('assets/images/through.png'),
      dark: require('assets/images/through-dark.png')
    },
    nailbed: {
      light: require('assets/images/nailbed.png'),
      dark: require('assets/images/nailbed-dark.png')
    },
    tendon: {
      light: require('assets/images/tendon.png'),
      dark: require('assets/images/tendon-dark.png')
    },
    fascia: {
      light: require('assets/images/fascia.png'),
      dark: require('assets/images/fascia-dark.png')
    },
    joint: {
      light: require('assets/images/joint.png'),
      dark: require('assets/images/joint-dark.png')
    },
    none: {
      light: require('assets/images/none.png'),
      dark: require('assets/images/none-dark.png')
    },
  }
}

export default imageList