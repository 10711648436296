import React, { forwardRef } from 'react'
import { Platform, FlatList as RNFlatList } from 'react-native'
import { BottomSheetFlatList } from '@gorhom/bottom-sheet'

const FlatList = forwardRef((props, ref) => {

  if (Platform.OS === 'web') {
    return <RNFlatList ref={ref} {...props} />
  } else {
    return (
      <BottomSheetFlatList ref={ref} {...props} />
    )
  }

})

export default FlatList