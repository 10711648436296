import { LogBox } from 'react-native'

const removeLogBox = () => {
  LogBox.ignoreLogs([
    '[react-native-gesture-handler]',
		'Require cycle:',
    '[Reanimated]',
  ])
}

export {
  removeLogBox,
}