import { makeAutoObservable } from 'mobx'
import { pickerStoreDebug } from 'debug/settings'

export class PickerStore {

  parent = {
    open: false,
    value: null,
  }
  child = {
    open: false,
    value: null,
  }

  constructor(rootStore) {
    this.rootStore = rootStore
		makeAutoObservable(this)
  }

  setParentValue(value) {
    this.parent.value = value
    this.child.value = null
  }

  setChildValue(value) {
    this.child.value = value
  }

  get parentValue() {
    return this.parent.value
  }

  get childValue() {
    return this.child.value
  }

  setParentOpenState(state) { this.parent.open = state }
  setChildOpenState(state) { this.child.open = state }

  dismissAll() {
    this.parent.open = false
    this.child.open = false
  }

  reset() {
    this.parent.value = null
    this.child.value = null
  }

  loadDebug() {
    this.parent.value = pickerStoreDebug.parent.value
    this.child.value = pickerStoreDebug.child.value
  }

}