import { StyleSheet } from 'react-native'
import { Theme } from 'styles'

const themedTaxonomyButton = (colors) => StyleSheet.create({
  button: {
    flex: 1,
    padding: Theme.spacing.layout,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.light,
  },
  buttonActive: {
    backgroundColor: colors.text,
  },
  buttonDisabled: {
    opacity: 0.3,
  },
  buttonText: {
    fontSize: Theme.font.size.sm,
    letterSpacing: 1,
  },
  buttonTextActive: {
    color: colors.background,
  },
})

export {
  themedTaxonomyButton
}