import React, { useState, useMemo, useRef } from 'react'
import { StyleSheet, Keyboard, View, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DropDownPicker from 'react-native-dropdown-picker'
import { send, withHapticSelection, withHapticImpact } from 'utils'
import { Text, Button, Icon, SendButton } from 'components'
import { Theme, themedPicker } from 'styles'

const Feedback = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
  const { bottom } = useSafeAreaInsets()
	const { colors } = useTheme()
  const picker = themedPicker(colors)

  const [ email, setEmail ] = useState(appStore.defaultEmail)
  const [ open, setOpen ] = useState(false)
  const [ topic, setTopic ] = useState()
  const [ topics, setTopics ] = useState([
    { label: 'Repair Recommendations', value: 'recommendation' },
    { label: 'Technique', value: 'technique' },
    { label: 'Procedure Note', value: 'procedure_note' },
    { label: 'Precautions', value: 'precautions' },
    { label: 'Prophylaxis', value: 'prophylaxis' },
    { label: 'Other', value: 'other' },
  ])
  const [ content, setContent ] = useState(null)
  const [ status, setStatus ] = useState(null)

  const messageRef = useRef(null)

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    scrollView: {
      maxWidth: Theme.layout.maxWidth,
      paddingTop: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      paddingBottom: Theme.spacing.layout + bottom,
    },

    intro: {
      marginBottom: Theme.spacing.layout,
    },

    fieldset: {
      marginBottom: Theme.spacing.layout,
    },

    picker: {
      marginBottom: Theme.spacing.layout,
    },

    label: {
      marginBottom: Theme.spacing.border,
    },

    input: {
      fontSize: Theme.font.size.h3,
      fontFamily: Theme.font.family.regular,
      padding: Theme.spacing.thin,
      backgroundColor: colors.light,
      color: colors.text,
    },

    textarea: {
      textAlignVertical: 'top',
      height: 150,
    },
  })

  const handleEmailSubmit = () => {
    setOpen(true)
  }

  const handlePickerSelect = () => {
    withHapticImpact('Light')
    messageRef.current.focus()
  }

  const handleSend = async () => {
    try {
      setStatus('loading')
      const message = await send('feedback', {
        email: email,
        topic: topic,
        content: content,
      })
      setStatus('success')
    }
    catch (error) {
      setStatus('error')
    }
  }

  const handleReset = () => {
    setStatus(null)
    setEmail(appStore.defaultEmail)
    setTopic(null)
    setContent(null)
  }

  const handleDismiss = () => {
    Keyboard.dismiss()
    setOpen(false)
  }

  return (
    <View style={styles.wrap}>
      <KeyboardAwareScrollView 
        contentContainerStyle={styles.scrollView}
        keyboardDismissMode={'on-drag'}
        onScrollBeginDrag={handleDismiss}
      >
        <View style={styles.intro}>
          <Text>Help us improve Suture.app! Share edits or other suggestions directly with us using the form below.</Text>
        </View>

        <View style={styles.fieldset}>
          <Text style={styles.label} size={'h3'} weight={'bold'}>Email:</Text>
          <TextInput
            style={styles.input}
            autoComplete={'email'}
            autoCorrect={false}
            autoCapitalize={'none'}
            placeholder={'Email address'}
            placeholderTextColor={colors.dark}
            defaultValue={appStore.defaultEmail}
            value={email}
            keyboardType={'email-address'}
            onChangeText={setEmail}
            returnKeyType={'next'}
            textContentType={'emailAddress'}
            onSubmitEditing={handleEmailSubmit}
          />
        </View>

        <Text style={styles.label} size={'h3'} weight={'bold'}>Topic:</Text>
        <DropDownPicker
          open={open}
          value={topic}
          items={topics}
          setOpen={setOpen}
          setValue={setTopic}
          setItems={setTopics}
          onPress={() => withHapticSelection()}
          onSelectItem={handlePickerSelect}
          placeholder={'Select a Topic'}
          disableBorderRadius={true}
          listMode={'SCROLLVIEW'}
          style={[styles.picker, picker.wrap]}
          itemStyle={picker.item}
          textStyle={picker.text}
          dropDownContainerStyle={picker.dropdownContainer}
          listItemContainerStyle={picker.dropdownItemContainer}
          selectedItemLabelStyle={picker.dropdownItemLabelActive}
          ArrowDownIconComponent={() => <Icon name={'caret-down'} style={picker.icon} />}
          ArrowUpIconComponent={() => <Icon name={'caret-up'} style={picker.icon} />}
          TickIconComponent={() => <Icon name={'checkmark'} style={picker.icon} />}
        />

        <View style={[styles.fieldset, { marginTop: Theme.spacing.layout }]}>
          <Text style={styles.label} size={'h3'} weight={'bold'}>Message:</Text>
          <TextInput
            ref={messageRef}
            style={[styles.input, styles.textarea]}
            placeholder={'Message'}
            placeholderTextColor={colors.dark}
            onChangeText={setContent}
            value={content}
            multiline={true}
            numberOfLines={6}
          />
        </View>

        <SendButton
          onSubmit={handleSend}
          onReset={handleReset}
          status={status}
        />

      </KeyboardAwareScrollView>
    </View>
  )
})

export default Feedback