import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { BottomSheetHandleProps } from '@gorhom/bottom-sheet'
import { Shadow } from 'react-native-shadow-2'
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
} from 'react-native-reanimated'
import { toRad } from 'react-native-redash'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

export const transformOrigin = ({ x, y }, ...transformations) => {
  'worklet'
  return [
    { translateX: x },
    { translateY: y },
    ...transformations,
    { translateX: x * -1 },
    { translateY: y * -1 },
  ]
}

const Handle = ({ style, animatedIndex }) => {
	const { colors } = useTheme()

  const styles = StyleSheet.create({
    header: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    indicator: {
      position: 'absolute',
      width: 16,
      height: 4,
      backgroundColor: colors.dark,
    },
    leftIndicator: {
      borderTopStartRadius: 2,
      borderBottomStartRadius: 2,
    },
    rightIndicator: {
      borderTopEndRadius: 2,
      borderBottomEndRadius: 2,
    },
  })

  const indicatorTransformOriginY = useDerivedValue(() =>
    interpolate(animatedIndex.value, [0, 1, 2], [-1, 0, 1], Extrapolate.CLAMP)
  )
  const containerStyle = useMemo(() => [styles.header, style], [style])

  const leftIndicatorStyle = useMemo(
    () => ({
      ...styles.indicator,
      ...styles.leftIndicator,
    }),
    []
  )
  const leftIndicatorAnimatedStyle = useAnimatedStyle(() => {
    const leftIndicatorRotate = interpolate(
      animatedIndex.value,
      [0, 1, 2],
      [toRad(-30), 0, toRad(30)],
      Extrapolate.CLAMP
    )
    const leftIndicatorTop = interpolate(
      animatedIndex.value,
      [0, 1, 2],
      [14, 18, 26],
      Extrapolate.CLAMP
    )
    return {
      transform: transformOrigin(
        { x: 0, y: indicatorTransformOriginY.value },
        {
          rotate: `${leftIndicatorRotate}rad`,
        },
        {
          translateX: -8,
        }
      ),
      top: leftIndicatorTop,
    }
  })
  const rightIndicatorStyle = useMemo(
    () => ({
      ...styles.indicator,
      ...styles.rightIndicator,
    }),
    []
  )
  const rightIndicatorAnimatedStyle = useAnimatedStyle(() => {
    const rightIndicatorRotate = interpolate(
      animatedIndex.value,
      [0, 1, 2],
      [toRad(30), 0, toRad(-30)],
      Extrapolate.CLAMP
    )
    const rightIndicatorTop = interpolate(
      animatedIndex.value,
      [0, 1, 2],
      [14, 18, 26],
      Extrapolate.CLAMP
    )
    return {
      transform: transformOrigin(
        { x: 0, y: indicatorTransformOriginY.value },
        {
          rotate: `${rightIndicatorRotate}rad`,
        },
        {
          translateX: 8,
        }
      ),
      top: rightIndicatorTop,
    }
  })

  return (
    <>
      <Shadow
        sides={'top'}
        startColor={'rgba(0,0,0,0.1)'}
        finalColor={'rgba(0,0,0,0)'}
        distance={20}
        viewStyle={{alignSelf: 'stretch',}}
      >
        <View />
      </Shadow>
      <Animated.View
        style={containerStyle}
        renderToHardwareTextureAndroid={true}
      >
        <Animated.View style={[leftIndicatorStyle, leftIndicatorAnimatedStyle]} />
        <Animated.View
          style={[rightIndicatorStyle, rightIndicatorAnimatedStyle]}
        />
      </Animated.View>
    </>
  )
}

export default Handle