import React from 'react'
import { View, StyleSheet } from 'react-native'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useTheme, useRoute } from '@react-navigation/native'
import { TabView } from 'components'
import { Theme } from 'styles'

const Basics = observer((props) => {

  const { rootStore } = useStores()
  const { appStore, contentStore } = rootStore
	const { colors } = useTheme()
  const route = useRoute()

  const parent = route.name.toLowerCase()
  const pages = contentStore.getChildPagesBySlug(parent)

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
  })

  return (
    <View style={styles.wrap}>
      <TabView pages={pages} parent={parent} />
    </View>
  )
})

export default Basics